@use "../../../app/styles/variables" as v;

.why-art__main-container {
  padding: 96px 24px;
  background: v.$pc-gray-50;
  display: flex;
  width: 100%;

  .main__container {
    background: v.$white;
    box-shadow: 0 24px 48px -12px #1018282e;
    color: v.$pc-gray-900;
    column-gap: 64px;
    padding: 50px;
  }
}

.why-art__container {
  color: v.$pc-gray-900;
  padding: 50px;
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
  background: v.$white;
  column-gap: 64px;
}

.why-art__description {
  max-width: 356px;
  width: 100%;

  .subtitle {
    font-family: NotoSemiBold;
    color: v.$pc-accent-700;
    line-height: 24px;
  }

  .title {
    font-family: NotoSemiBold;
    color: v.$pc-gray-900;
    line-height: 44px;
  }
}

.why-art__content {
  column-gap: 64px;
}

.why-art__item {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  max-width: 346px;
  min-height: 86px;
  margin-bottom: 32px;

  .icon {
    margin-top: 2px;
  }

  img {
    width: 24px;
    height: 24px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.why-art__item-content {
  gap: 8px;
  display: grid;

  .title {
    font-family: NotoSemiBold;
    line-height: 30px;
  }

  .description {
    color: v.$pc-gray-500;
    line-height: 24px;
  }
}

@media (max-width: 1350px) {
  .why-art__main-container {
    .why-art__description {
      max-width: 228px;
    }
  }
}

@media (max-width: 1220px) {
  .why-art__container {
    max-width: 720px;
    flex-direction: column;
    padding: 36px;
  }

  .why-art__main-container {
    padding: 80px 24px;

    .main__container {
      flex-direction: column;
    }

    .why-art__description {
      max-width: 100%;
    }

    .title {
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 48px;
    }

    .why-art__content {
      column-gap: 48px;
    }

    .why-art__item {
      margin-bottom: 24px;
      max-width: 300px;
      gap: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        width: 24px;
        height: 24px;
      }

      .why-art__item-content {
        gap: 0;
        h3 {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 8px;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .main__container {
    flex-direction: column;
  }
  .why-art__container {
    .why-art__item {
      max-width: 100%;
      min-height: auto;
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 606px) {
  .why-art__container {
    padding: 24px;

    .why-art__content {
      flex-wrap: nowrap;
      flex-direction: column;
    }

    div.why-art__item-content {
      gap: 0;

      .title {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 4px;
      }
    }
  }
}
