@use "../../../../app/styles/variables" as v;

.upload-archive-popup__wrapper {
    border-radius: 12px;
    background   : v.$white;
    padding      : 20px 16px;
    width        : 343px;
    z-index      : 9;
    margin       : 16px;

    .upload-archive-popup__title {
        line-height  : 28px;
        font-family  : NotoSemiBold;
        color        : v.$pc-gray-900;
        margin-bottom: 8px;
    }

    .upload-archive-popup__subtitle {
        line-height: 20px;
        color      : v.$pc-gray-500;
    }

    .upload-archive-popup__radio {
        margin-top: 16px;
    }

    .upload-archive-popup__link, 
    .upload-archive-popup__file {
        margin-top: 6px;

        .input__children {
            label {
                display: none;
            }
        }
    }

    .upload-archive-popup__link {
        &.disabled {
            .input__container {
                background  : v.$pc-gray-50;
                border-color: v.$pc-gray-300;
            }
        }
    }

    .upload-archive-popup__file {
        &.disabled {
            .uploader-wrapper {
                background  : v.$pc-gray-50;
                border-color: v.$pc-gray-200;
            }

            .marked {
                color: v.$pc-gray-300;
            }
        }
    }

    .upload-archive__btn {
        width          : 100%;
        justify-content: center;
        margin-top     : 23px;

        &.publish{
            margin-bottom: 12px;
        }
    }

    .upload-archive-popu__actions {
        margin-top: 24px;

        .upload-archive-popu__btn {
            width          : 100%;
            display        : flex;
            justify-content: center;

            &.publish {
                margin-bottom: 12px;
            }
        }
    }

    .error {
        margin-top: 5px;
        display   : block;
    }
}

@media (max-height: 724px) {
    .background-default {
        &.upload-archive {
            align-items: baseline;
        }
    }
}