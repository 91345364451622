@use "../../../app/styles/variables" as v;

.blog-list__container {
  .blog-list__title {
    h4 {
      color: v.$pc-gray-900;
      font-family: NotoBold;
      font-size: 36px;
      line-height: 44px;
    }
  }

  .ant-list-item {
    padding: 32px 0;

    .ant-list-item-meta {
      flex-direction: column;

      .ant-list-item-meta-avatar {
        h6 {
          color: v.$pc-accent-700;
          font-family: NotoBold;
        }
      }

      .ant-list-item-meta-content {
        width: 100%;

        .ant-list-item-meta-title {
          font-size: 24px;
          color: v.$pc-gray-900;

          a:hover {
            color: v.$pc-accent-700;
            text-decoration-line: underline;
          }
        }
        .ant-list-item-meta-description {
          font-size: 18px;
          line-height: 28px;
          color: v.$pc-gray-500;
        }
      }
    }

    .ant-list-item-extra {
      align-self: center;
      height: 375px;
      width: 375px;

      img {
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        max-height: 375px;
      }
    }
  }
}

@media (max-width: 1065px) {
  .blog-list__container {
    .ant-list-item {
      flex-direction: column-reverse;
      padding: 24px 0;
      gap: 24px;

      .ant-list-item-main {
        width: 100% !important;
      }

      .ant-list-item-extra {
        margin: auto;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .blog-list__container {
    .ant-list-item {
      .ant-list-item-extra {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: auto;
        }
      }
    }
  }
}
