@use "../../../app/styles/variables" as v;

.new-imgs__container {
    padding: 0 32px 96px;

    .new-imgs__title {
        line-height  : 24px;
        font-family  : NotoBold;
        font-size: 36px;
        color        : v.$pc-gray-900;
        margin-bottom: 32px;
    }

    .new-imgs__slider {
        height: 200px;

        .slick-slider {
            margin-bottom: 0;
        }

        .new-imgs__img {
            height   : 200px;
            min-width: 70px;
        }

        .carousel-arrow {
            display: flex !important;
            position : absolute;
            align-items: center;
            justify-content: center;

            &::before {
                content: "";
            }
        }

        .slick-next {
            right: 16px;
        }
        .slick-prev {
            left: 16px;
        }

        .slick-list {
            overflow: hidden!important;
        }
    }

    .new-imgs__action {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;

        a {
            display: block;
        }
    }
}

@media (max-width: 768px) {
    .new-imgs__container {
        padding: 0 24px 80px;

        .new-imgs__action {
            margin-top: 18px;
        }
    }
}

@media (max-width: 606px) {
    .new-imgs__container {
        padding: 0 16px 60px;

        .new-imgs__slider {
            .carousel-arrow{
                &.prev {
                    left: 8px;
                }
                &.next {
                    right: 8px;
                }
            }
        }

        .new-imgs__action {
            margin-top: 18px;
        }
    }
}
