@use "../../../app/styles/variables" as v;

.admin__aside {
    min-width     : 312px;
    max-width     : 312px;
    border-right  : 1px solid v.$pc-gray-200;
    position      : relative;

    .admin__aside-content {
      display       : flex;
      flex-direction: column;
      height        : calc(100vh - 142px);
      overflow-y    : auto;
      overflow-x    : hidden;
    }

    .admin__aside-search {
      border-bottom: 1px solid v.$pc-gray-200;
      padding      : 6px 16px;

      .search__container_withbg {
        margin: 0;
        background: v.$white;
      }

      .input__container {
        border    : none;
        box-shadow: none;

        .input__line {
          border    : none;
          box-shadow: none;
        }

        input {
          outline   : none;
          border    : none;
          box-shadow: none;
        }
      }
    }

    .project__data {
      margin-bottom     : 52px;

      &.bottom-align {
        margin-bottom: 76px;
      }
    }

    .project__create {
      display        : flex;
      position       : absolute;
      justify-content: center;
      padding        : 15px;
      top            : 80px;
      left           : 40px;

      &.fixed {
        top       : auto;
        left      : 0;
        bottom    : 0;
        background: v.$white;
        width     : 100%;
        border-top: 1px solid v.$pc-gray-200;

        .button {
          width          : 100%;
          justify-content: center;
        }
      }
    }
  }