@use "../../../app/styles/variables" as v;

.loader-progress__container {
    margin-top: 12px;
    border-radius: 8px;
    border: 1px solid v.$pc-accent-500;
    padding: 16px;

    .progress__name,
    .progress__size {
        line-height: 20px;
        font-family: NotoMedium;
        color: v.$pc-gray-700;
    }

    .progress__size {
        color: v.$pc-gray-500;
        margin-bottom: 4px;
        font-family: NotoSemiBold;
    }

    .loader-progress__content {
        flex-grow: 1;
    }

    .loader-progress__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .progress {
        flex-grow: 1;
        height: 10px;
    }

    .progress__persents {
        line-height: 20px;
        font-family: NotoMedium;
        color: v.$pc-gray-700;
        margin-left: 12px;
    }
}
