@use "../../../app/styles/variables" as v;

.background-default-zindex-9 {
    z-index: 9;
}

.security-form {
    padding: 32px;
    max-width: 360px;

    .security__title {
        line-height: 28px;
        font-family: NotoSemiBold;
        color: v.$pc-gray-900;
    }

    .security__subtitle {
        text-align: center;
        line-height: 20px;
    }
}