@use "../../../app/styles/variables" as v; 

.loading-admin__container {
    position       : absolute;
    top            : 0;
    bottom         : 0;
    right          : 0;
    left           : 0;
    display        : flex;
    align-items    : center;
    justify-content: center;
    background     : #ffffffdb;
    z-index        : 99;
    border-radius  : 12px;

    h3 {
        color: v.$pc-gray-700;
    }
}