@use "../../../app/styles/variables" as v;

.profile-navigate {
    position: fixed;
    width: 100%;
    background: v.$white;
    z-index: 9;

    .admin-navigate__tab {
        gap: 16px;
        height: 38px;
    }
}

@media (max-width: 670px) {
    .profile-navigate {
        padding: 0px 32px 0 20px;
    }
}

@media (max-width: 606px) {
    .profile-navigate {
        padding: 12px 8px 8px;
        top: 56px;
        border-top: 1px solid v.$pc-gray-100;

        .admin-navigate__tab {
            height: 30px;
        }

        .tab-link {
            &.md {
                line-height: 20px !important;
                font-size  : 14px !important;
                text-wrap: nowrap;
            }

            &.active {
                text-underline-offset: 14px !important;
            }
        }
    }
}
