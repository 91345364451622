@use "../../app/styles/variables" as v;

.avatar-label__container {
   .avatar__wrapper  {
      margin-right: 12px;
   }

   .author__name {
      display: flex;
      flex-wrap: wrap;
   }

   h6 {
      span {
         font-family: NotoSemiBold;
         color: v.$pc-gray-900;
         line-height: 20px;
      }
   }

   .button:active{
      box-shadow: none!important;
      background-color: transparent!important;
   }

   .avatar__wrapper:active{
      box-shadow: none!important;
   }
}
