@use "../../../../app/styles/variables" as v;

.notification-project__wrapper {
    position     : absolute;
    top          : 57px;
    width        : 100%;
    left         : 0;
    right        : 0;
    border-radius: 0px 0px 8px 8px;
    border-top   : none;
    padding      : 8px 32px;
    background   : v.$white;
    z-index      : 98;

    .notification-project__title {
        line-height: 20px;
        color      : v.$pc-gray-900;
        font-family: NotoSemiBold;
    }

    .notification-project__suptitle {
        line-height  : 24px;
        color        : v.$pc-gray-500;
        margin-bottom: 12px;

        &.down {
            display: none;
        }
    }

    .notification__show-less {
        margin-top: 10px;
        .button {
            padding: 0;
            img {
                transform: rotate(90deg);
            }

            &.up {
                img {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    .title__icon {
        margin-left: 4px;
    }

    .notification-project__info {
        display    : flex;
        align-items:center;
        flex-wrap  : wrap;
        gap        : 12px;

        .notification-project__time {
            display     : flex;
            align-items : center;
            //margin-right: 12px;
            //margin-left : 12px;

            img {
                margin-right: 4px;
                width       : 20px;
                height      : 20px;
            }

            span {
                line-height: 20px;
                font-size  : 14px;
                color      : v.$pc-gray-700;
            }
        }

        .notification-project__cost {
            display     : flex;
            align-items : center;
            //margin-right: 12px;

            img {
                margin-right: -1px;
                width       : 20px;
                height      : 20px;
            }

            span {
                line-height: 20px;
                font-size  : 14px;
                color      : v.$pc-gray-700;
            }
        }

        &.down {
            display: none;
        }
    }

    .notification__actions {
        display        : flex;
        justify-content: flex-end;
        margin-top     : 16px;

        button {
            width          : 194px;
            justify-content: center;
            padding        : 8px 14px;
            
            &:first-child {
                margin-right: 12px;
            }
        }
    }

    .remove__btn {
        position     : absolute;
        right        : 32px;
        top          : 8px;
        cursor       : pointer;
        border-radius: 50%;

        &:active {
            box-shadow: 0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
    }

    .notification__link {
        position   : absolute;
        right      : 32px;
        font-size  : 14px;
        line-height: 20px;
        font-family: NotoSemiBold;

        &::after {
            content          : "";
            position         : absolute;
            right            : -32px;
            display          : inline-block;
            background-image : url('../../../../shared/ui-kit/icons/arrow-accent700.svg');
            background-repeat: no-repeat;
            width            : 20px;
            height           : 20px;
            top              : 56%;
            transform        : translate(-50%, -50%) rotate(-45deg);
        }
    }
}

@media (max-width: 862px) {
    .notification-project__wrapper {
        padding: 12px 14px;

        .notification-project__descr {
            flex-direction: column-reverse;
            align-items   : normal;
        }

        .notification__link {
            position       : initial;
            justify-content: flex-end;
            border-bottom  : 1px solid v.$pc-gray-200;
            padding-bottom : 8px;
            margin-bottom  : 8px;
            border-radius  : 0;

            &::after {
                position   : initial;
                margin-left: 8px;
                transform  : translate(0%, 0%) rotate(-45deg);
            }
        }
    }
}
