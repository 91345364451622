@use "../../../app/styles/variables" as v;

.blog-posts__container {
  width: 100%;
}

.blog-posts__content {
  padding: 96px 32px;
  margin-bottom: 96px;
  text-align: center;
  line-height: 44px;
  background-color: v.$pc-gray-50;
}

.blog-posts__header {
  margin-bottom: 32px;

  h1 {
    color: v.$pc-gray-900;
    font-family: NotoSemiBold;
    margin-bottom: 24px;
    font-size: 36px;
    padding: 0;
  }

  h3 {
    line-height: 30px;
  }
}

.blog-posts__items {
  width: 100%;
  overflow: hidden;

  .blog-posts__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 88%;
    width: 376px;
    padding: 24px;
    text-align: left;
    background-color: v.$white;

    .date {
        color: v.$pc-accent-700;
        font-family: NotoBold;
    }

    .title {
        font-family: NotoBold;
        font-size: 18px;
        line-height: 28px;
        color: v.$pc-gray-900;
    }

    .text {
      line-height: 24px;
      display           : -webkit-box;
      overflow          : hidden;
    }

    .user-info {
        .avatar__wrapper {
            margin-right: 16px;
        }

        .author__name {
            font-family: NotoBold;
            font-size: 18px;
            color: v.$pc-gray-900;
            line-height: 28px;
        }
        .author__text {
            font-family: NotoRegular;
            font-size: 16px;
            color: v.$pc-gray-500;
            line-height: 24px;
        }

        h5 {
          padding: 0;
        }
    }
  }

  .ant-carousel .slick-prev::before,
  .ant-carousel .slick-next::before {
    color: transparent !important;
  }

  .ant-carousel {
    .slick-arrow {
      &.slick-disabled {
        display: none !important;
      }
    }

    .slick-prev {
      display: flex;
      background-color: v.$white;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border: 1px solid v.$pc-gray-200;

      &::after {
        color: v.$pc-gray-500;
        width: 16px;
        height: 16px;
        top: 0;
        bottom: 0;
        left: 6px;
        right: 0px;
        margin: auto;
        opacity: 1;
      }
    }

    .slick-next {
      display: flex;
      background-color: v.$white;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border: 1px solid v.$pc-gray-200;

      &::after {
        color: v.$pc-gray-500;
        width: 16px;
        height: 16px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 6px;
        margin: auto;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 768px) {
  .blog-posts__content {
    padding: 16px;
  }

  .blog-posts__header {
    h1 {
        
      margin-bottom: 24px;
    }

    h3 {
        margin-bottom: 32px;
    }
  }

  .blog-posts__content {
    .blog-posts__items {
      flex-direction: column;

      /*.blog-posts__item {
        max-width: 235px;
      }*/
    }
  }
}
