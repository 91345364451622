@use "../../../app/styles/variables" as v;

.link {
    display      : flex;
    border-radius: 8px;
    padding      : 8px 12px;
    font-family  : NotoSemiBold;
    text-align   : center;
    transition   : background-color 0.2s ease-in-out;

    img {
        margin-right: 8px;
    }

    /** SIZE */
    &.sm {
        line-height: 20px;
        font-size  : 14px;
        padding    : 8px 14px;
    }
    &.md {
        line-height: 20px;
        font-size  : 14px;
        padding    : 10px 16px;
    }
    &.lg {
        line-height: 24px;
        font-size  : 16px;
        padding    : 10px 18px;
    }
    &.xl {
        line-height: 24px;
        font-size  : 16px;
        padding    : 12px 20px;
    }
    &.xxl {
        line-height: 28px;
        font-size  : 18px;
        padding    : 16px 28px;
    }

    /** THEME **/
    /** PRIMARY BUTTON */
    &.primary {
        background-color: v.$pc-accent-600;
        color           : v.$white;
        
        &:hover {
            background-color: v.$pc-accent-700;
        }
        &:active {
            background-color: v.$pc-accent-600;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-accent-100;
        }
        &.disabled {
            background-color: v.$pc-accent-200;
            pointer-events  : none;
        }
    }

    &.primary-danger {
        background-color: v.$pc-error-600;
        color           : v.$white;

        &:hover {
            background-color: v.$pc-error-700;
        }
        &:active {
            background-color: v.$pc-error-600;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-error-100;
        }
        &.disabled {
            background-color: v.$pc-error-200;
            pointer-events  : none;
        }
    }

    /** SECONDARY BUTTON */
    &.secondary {
        background-color: v.$pc-accent-50;
        color           : v.$pc-accent-700;

        &:hover {
            background-color: v.$pc-accent-100;
        }
        &:active {
            background-color: v.$pc-accent-50;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-accent-100;
        }
        &.disabled {
            background-color: v.$pc-accent-25;
            color           : v.$pc-accent-300;
            pointer-events  : none;
        }
    }

    &.secondary-danger {
        background-color: v.$pc-error-50;
        color           : v.$pc-error-700;

        &:hover {
            background-color: v.$pc-error-100;
        }
        &:active {
            background-color: v.$pc-error-50;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-error-100;
        }
        &.disabled {
            background-color: v.$pc-error-25;
            color           : v.$pc-error-300;
            pointer-events  : none;
        }
    }

    /** SECONDARY GRAY BUTTON */
    &.secondary-gray {
        background-color: v.$white;
        color           : v.$pc-gray-700;
        border          : 1px solid v.$pc-gray-300;

        &:hover {
            background-color: v.$pc-gray-50;
        }
        &:active {
            background-color: v.$white;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-gray-100;
        }
        &.disabled {
            background-color: v.$white;
            border          : 1px solid v.$pc-gray-200;
            color           : v.$pc-gray-300;
            pointer-events  : none;
        }
    }

    &.secondary-gray-danger {
        background: v.$white;
        border    : 1px solid v.$pc-error-300;
        color     : v.$pc-error-700;

        &:hover {
            background-color: v.$pc-error-50;
        }
        &:active {
            background-color: v.$white;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-error-100;
        }
        &.disabled {
            background-color: v.$white;
            border          : 1px solid v.$pc-error-200;
            color           : v.$pc-error-300;
            pointer-events  : none;
        }
    }

    /** TERTIARY BUTTON */
    //.tertiary {}

    &.tertiary-gray {
        color  : v.$pc-gray-500;
        padding: 0;

        &:hover {
            color                : v.$pc-gray-600;
            text-decoration      : underline;
            text-underline-offset: 2px;
            text-decoration-color: inherit;
        }

        &:active {
            color: v.$pc-gray-500;
        }

        &.disabled {
            color         : v.$pc-gray-300;
            pointer-events: none;
        }
    }

    /** TEXT BUTTON LINK */
    &.link-color {
        background-color: transparent;
        color     : v.$pc-accent-700;
        padding   : 0 6px;

        &:hover {
            color : v.$pc-accent-800;
        }
        &:active {
            color : v.$pc-accent-700;
        }
        &.disabled {
            color         : v.$pc-gray-300;
            pointer-events: none;

            img {
                opacity: .5;
            }
        }
    }

    &.link-gray {
        background-color: transparent;
        color     : v.$pc-gray-500;
        padding   : 0 6px;

        &:hover {
            color : v.$pc-gray-800;
        }
        &:active {
            color : v.$pc-gray-700;
        }
        &.disabled {
            color         : v.$pc-gray-300;
            pointer-events: none;

            img {
                opacity: .5;
            }
        }
    }

    &.text {
        color   : v.$pc-accent-700;
        position: relative;
        padding : 0;

        &:hover {
            text-decoration      : underline;
            text-underline-offset: 2px;
            text-decoration-color: inherit;
        }

        &.disabled {
            color         : v.$pc-gray-300;
            pointer-events: none;

            img {
                opacity: .5;
            }
        }
    }

    &.link-underlined {
        color  : v.$pc-accent-100;
        padding: 0;

        &:hover {
            color                : v.$pc-accent-200;
            text-decoration      : underline;
            text-underline-offset: 2px;
            text-decoration-color: inherit;
        }

        &:active {
            color: v.$pc-accent-400;
        }

        &.disabled {
            color         : v.$pc-gray-300;
            pointer-events: none;
        }
    }
}
