@use "../../../app/styles/variables" as v;

.icon-featured__container {
    border-radius  : 50%;
    display        : flex;
    align-items    : center;
    justify-content: center;

    /* SIZE */
    &.xs {
        width : 24px;
        height: 24px;
        border: 2px solid;
    }

    &.sm {
        width : 32px;
        height: 32px;
        border: 4px solid;
    }

    &.md {
        width : 40px;
        height: 40px;
        border: 6px solid;
    }

    &.lg {
        width : 48px;
        height: 48px;
        border: 8px solid;
    }

    &.xl {
        width : 56px;
        height: 56px;
        border: 10px solid;
    }

    /* THEME */
    &.primary {
        border-color: v.$pc-accent-50;
        background  : v.$pc-accent-100;
    }

    &.gray {
        border-color:v.$pc-gray-50;
        background  : v.$pc-gray-100;
    }

    &.error {
        border-color: v.$pc-error-50;
        background  : v.$pc-error-100;
    }

    &.warning {
        border-color: v.$pc-warning-50;
        background  : v.$pc-warning-100;
    }

    &.succes {
        border-color: v.$pc-success-50;
        background  : v.$pc-success-100;
    }


}