@use "../../../app/styles/variables" as v;

.badge__container {
    border-radius: 16px;

    .badge__text {
        font-family: NotoMedium;
        white-space: nowrap;
    }

    .badge__status {
        width        : 6px;
        height       : 6px;
        border-radius: 50%;
        margin-right : 7px;
        margin-left  : -1px; 
    }

    .badge__icon {
        width        : 16px!important;
        height       : 16px!important;
        border-radius: 50%;
        margin-right : 6px;
        margin-left  : -6px;
    }

    /* SIZE */

    &.sm {
        padding: 2px 8px;

        .badge__text {
            font-size  : 12px;
            line-height: 18px;
        }
    }
    &.md {
        padding: 2px 10px;

        .badge__text {
            font-size  : 14px;
            line-height: 20px;
        }
    }

    &.lg {
        padding: 4px 12px;

        .badge__text {
            font-size: 14px;
            line-height: 20px;
        }
    }

    /* THEMES */

    &.gray {
        background: v.$pc-gray-100;

        .badge__text {
            color: v.$pc-gray-700;
        }

        .badge__status {
            background: v.$pc-gray-500;
        }
    }

    &.primary {
        background: v.$pc-accent-50;

        .badge__text {
             color: v.$pc-accent-700;
        }

        .badge__status {
            background: v.$pc-accent-500;
        }
    }

    &.error {
        background: v.$pc-error-50;

        .badge__text {
             color: v.$pc-error-700;
        }

        .badge__status {
            background: v.$pc-error-500;
        }
    }

    &.warning {
        background: v.$pc-warning-50;

        .badge__text {
             color: v.$pc-warning-600;
        }

        .badge__status {
            background: v.$pc-warning-500;
        }
    }

    &.success {
        background: v.$pc-success-50;

        .badge__text {
             color: v.$pc-success-700;
        }

        .badge__status {
            background: v.$pc-success-500;
        }
    }

    &.blue-gray {
        background: v.$sc-blue-gray-50;

        .badge__text {
             color: v.$sc-blue-gray-700;
        }

        .badge__status {
            background: v.$sc-blue-gray-500;
        }
    }

    &.blue-light {
        background: v.$sc-blue-light-50;

        .badge__text {
             color: v.$sc-blue-light-700;
        }

        .badge__status {
            background: v.$sc-blue-light-500;
        }
    }

    &.violet{
        background: v.$sc-violet-50;

        .badge__text {
             color: v.$sc-violet-700;
        }

        .badge__status {
            background: v.$sc-violet-500;
        }
    }

    &.purple {
        background: v.$sc-purple-50;

        .badge__text {
             color: v.$sc-purple-700;
        }

        .badge__status {
            background: v.$sc-purple-500;
        }
    }

    &.pink {
        background: v.$sc-pink-50;

        .badge__text {
             color: v.$sc-pink-700;
        }

        .badge__status {
            background: v.$sc-pink-500;
        }
    }

    &.rose {
        background: v.$sc-rose-50;

        .badge__text {
             color: v.$sc-rose-500;
        }

        .badge__status {
            background: v.$sc-rose-700;
        }
    }

    &.orange {
        background: v.$sc-orange-50;

        .badge__text {
             color: v.$sc-orange-500;
        }

        .badge__status {
            background: v.$sc-orange-700;
        }
    }

    &.green {
        background: v.$pc-success-500;

        .badge__text {
             color: v.$white;
        }

        .badge__status {
            background: v.$pc-success-700;
        }
    }
}
