.background-default {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    background     : rgba(52, 64, 84, 0.30);
    position       : fixed;
    top            : 0;
    bottom         : 0;
    left           : 0;
    right          : 0;
    backdrop-filter: blur(4px);
    z-index        : 100;
}
