@use "../../../app/styles/variables" as v;

.user-project__container {
    padding: 12px 16px;
    display: flex;
    cursor : pointer;

    &:hover {
        background: v.$pc-gray-50;
    }

    &.current {
        background: v.$pc-accent-100;
    }

    .user-project__wrapper {
        width       : 64px;
        min-width   : 64px;
        height      : 64px;
    }

    .user-project__descr {
        width       : 200px;
        padding-left: 12px;

        .project-status {
            position: relative;
            display : flex;

            .status__container {
                position: absolute;
                right   : 0;
            }
        }
    }

    .user-project__preview-img {
        height          : 64px;
        object-fit      : cover;
        width           : 100%;
        object-position : center;
        border-radius   : 4px;
        border          : 1px solid v.$pc-gray-200;
    }

    .user-project-licennses__icon {
        position     : absolute;
        bottom       : -7px;
        right        : -7px;

        .icon-ava__img {
            width           : 21px;
            height          : 21px;
            padding         : 1px;
            background-color: #4E5BA6;
            border          : 1px solid #fff;
            border-radius   : 50%;
        }
    }

    .user-project-name {
        line-height  : 24px;
        font-family  : NotoMedium;
        color        : v.$pc-gray-700;
        width        : 190px;
        white-space  : nowrap;
        overflow     : hidden;
        text-overflow: ellipsis;
    }

    .user-project-supname {
        line-height: 24px;
        color      : v.$pc-gray-700;
        white-space: nowrap;
    }

    .user-project-descr{
        width   : 204px;
        overflow: hidden;
    }

    .user-project-executor_content {
        margin-left  : 4px;
        width        : 160px;
        white-space  : nowrap;
        overflow     : hidden;
        text-overflow: ellipsis;
        line-height  : 24px;
    }

    .new-project__preview {
        width          : 64px;
        height         : 64px;
        border-radius  : 4px;
        border         : 1px solid v.$pc-accent-300;
        background     : #f5f8ff;
        display        : flex;
        align-items    : center;
        justify-content: center;
        margin-right   : 12px;

        h3 {
            line-height: 30px;
            color      : v.$pc-accent-600;
            font-family: NotoMedium;
        }
    }

    .notification__link {
        font-size  : 14px;
        line-height: 20px;
        font-family: NotoSemiBold;
        margin-top : 8px;

        &::after {
            content          : "";
            background-image : url('../../../shared/ui-kit/icons/arrow-accent700.svg');
            background-repeat: no-repeat;
            width            : 20px;
            height           : 20px;
            transform        : rotate(-45deg);
            margin-left      : 8px;
        }
    }
}

@media (max-width: 671px) {
    .user-project__container {
        .user-project__descr {
            width: 100%;

            .user-project-name {
                display           : block;
                display           : -webkit-box;
                width             : calc(100% - 40px);
                white-space       : wrap;
                overflow-wrap     : break-word;
                line-clamp        : 2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}
