@use "../../../app/styles/variables" as v;

.card__wrapper {
    .card__title {
        line-height: 28px;
        color      : v.$pc-gray-900;
        font-family: NotoSemiBold;
        margin-bottom: 24px;
    }

    .card__container {
        width: 100%;

        &.error {
            .card__mount {
                border-color: v.$pc-error-500;
            }
        }
    }

    .card__mount {
        border-radius: 8px;
        border       : 1px solid v.$pc-gray-300;
        box-shadow   : 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding      : 10px 14px 10px 10px;
        margin-top   : 6px;

        &.StripeElement--invalid {
            border-color: v.$pc-error-500;
        }
    }

    label {
        font-size  : 14px;
        line-height: 20px;
        font-family: NotoMedium;
        color      : v.$pc-gray-700;
    }
}
