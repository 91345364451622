.info__avatar {
    width       : 804px;
    margin-right: 64px;
    max-height  : 600px;

    img {
        max-width : 100%;
        max-height: 600px;
        margin    : 0 auto;
        display   : inherit;
    }
}