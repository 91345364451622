.products__container-actions {
    .action__text {
        text-wrap  : nowrap;
        line-height: 24px;
        font-family: NotoMedium;
    }

    .antd-dropdown__container {
        width: 110px;

        .arrow__icon {
            width: 15px;
            height: 15px;
        }
    }
}

@media (max-width: 606px) {
    .action__text {
        display: none;
    }

    .dropdown__container {
        .select-search-input {
            font-size: 14px;
            line-height: 20px;
            height: 40px;
        }
    }

    .dropdown__container {
        .select-search-option {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

@media (max-width: 475px) {
    .dropdown__container {
        width: 118px;
    }
}
