@use "../../../../../app/styles/variables" as v;

.table-sales__container {
  width: 100%;

  .admin__table {
    width: 100%;
    border-radius: 8px;
    border: 1px solid v.$pc-gray-200;
    background: v.$white;

    .admin__table-title {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      background-color: v.$pc-gray-25;
      border-radius: 12px 12px 0px 0px;
      border-bottom: 1px solid v.$pc-gray-200;

      .table-title__actions {
        gap: 24px;
      }

      .check__container {
        padding-left: 20px;
      }

      .button {
        padding: 0;
        line-height: 20px;
        .button__container-icon {
          margin: 0;
        }
      }

      .total__wrapper {
        line-height: 20px;
        color: v.$pc-gray-400;
      }
    }
  }

  .admin__table-header,
  .admin__table-row {
    width: 100%;
  }

  .admin__table-header {
    display: flex;
    height: 44px;
    justify-content: space-between;
    background-color: v.$pc-gray-50;

    .button {
      .ml-15 {
        margin-left: 4px;
      }

      .button__img-right {
        height: 16px;
        width: 16px;
      }
    }

    .product-name,
    .product-customer,
    .product-date,
    .product-status,
    .product-earned {
      line-height: 20px;
      color: v.$pc-gray-500;
      font-family: NotoMedium;
      display: flex;
      align-items: center;
      padding: 12px 24px;
    }

    .product-name {
      min-width: 270px;
    }

    .product-customer {
      min-width: 220px;
    }

    .product-date {
      min-width: 156px;
    }

    .product-status {
      min-width: 270px;
      max-width: 270px;

      h6 {
        line-height: 20px;
        color: v.$pc-gray-500;
        font-family: NotoMedium;
      }

      .button.link-gray {
        line-height: 20px;
        color: v.$pc-gray-500;
        font-family: NotoMedium;

        &:hover {
          color: v.$pc-gray-500;
        }
      }
    }
    
    .product-earned {
      min-width   : 112px;
      margin-right: 16px;
    }
  }

  .admin__table-body {
    position: relative;
    height: calc(100vh - 329px);
    overflow: auto;
    overflow-y: scroll;

    
    .sales-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .empty-title {
        align-items: center;
        background-color: v.$pc-gray-100;
        border-radius: 16px;
        color: v.$pc-gray-700;
        display: flex;
        font-family: NotoMedium;
        justify-content: center;
        line-height: 20px;
        padding: 4px 12px;
        width: 170px;
      }
    }

    .loading-admin__container {
      z-index: 0;
    }
  }

  .admin__table-row {
    justify-content: space-between;
    height: 68px;
    cursor: auto;

    &:hover {
      background-color: transparent !important;
    }
  }

  .product-name {
    min-width: 270px;
    width: 270px;
    padding: 6px 24px;

    .modal-image {
      .modal__children {
        margin-right: 12px;
        min-width: 56px;
        max-width: 56px;
        height: 56px;
        border-radius: 4px;
        border: 1px solid v.$pc-gray-200;
        overflow: hidden;

        img {
          width: 100%;
          height: 56px;
          object-fit: cover;
        }
      }
    }

    .product-title {
      overflow: hidden;
      max-height: 50px;
    }

    h5 {
      line-height: 24px;
      color: v.$pc-gray-700;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }

    h6 {
      line-height: 20px;
      color: v.$pc-gray-500;
    }
  }

  .product-customer {
    min-width: 220px;
    width: 220px;
    padding: 12px 24px;

    h5 {
      line-height: 24px;
      color: v.$pc-gray-700;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
    }

    h6 {
      line-height: 20px;
      color: v.$pc-gray-500;
    }
  }

  .product-date {
    width: 156px;
    min-width: 156px;
    padding: 12px 24px;

    h5 {
      font-family: NotoMedium;
      line-height: 24px;
      color: v.$pc-gray-500;
    }

    h6 {
      line-height: 20px;
      color: v.$pc-gray-500;
    }
  }

  .product-status {
    min-width: 270px;
    max-width: 270px;
    padding: 16px 24px;
  }

  .product-earned {
    min-width: 112px;
    padding: 16px 24px;
    text-align: center;
  }

  .pagination__container {
    margin: 0;
    padding: 16px 0;
    position: relative;

    .antd-dropdown__container {
      position: absolute;
      width: 83px;
      right: 22px;

      .input__wrapper {
        top: -175px;
      }
    }
  }
}
