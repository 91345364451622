@use "../../../app/styles/variables" as v;

.products__container-title{
  display   : flex;
  justify-content: center;
  margin-top: 72px;
  text-align: center;
  overflow  : hidden;
  position  : relative;
  width     : 100%;
  height    : 162px;
  transition: all 0.5s ease;

  .title-hide-box {
    position  : absolute;
    transition: all 0.5s ease;
  }

  h1 {
   font-family  : NotoSemiBold;
   color        : v.$pc-gray-900;
   margin-bottom: 24px;
   line-height  : 44px;
  }

  h3 {
   margin-bottom: 64px;
   line-height  : 30px;
  }

  .count{
   color         : v.$pc-accent-700;
   margin-left   : 4px;
   margin-right  : 4px;
   margin-bottom : 0;
  }

  &.hide {
    height: 0;

    .title-hide-box {
      transform: translateY(-162px);
    }
  }
}

@media (max-width: 1065px) {
  .products__container-title{
    margin-top: 56px;
    height    : 130px;
  
    h1 {
      font-size    : 24px;
      margin-bottom: 20px;
      line-height  : 32px;
    }
  
    h3 {
      margin-bottom: 48px;
    }
  
    &.hide {  
      .title-hide-box {
        transform: translateY(-130px);
      }
    }
  }
}

@media (max-width: 606px) {
  .products__container-title{
    margin-top: 32px;
    height    : 96px;
  
    h1 {
      font-size    : 18px;
      margin-bottom: 16px;
      line-height  : 28px;
    }
  
    h3 {
      font-size    : 18px;
      line-height  : 28px;
      margin-bottom: 24px;
    }
  
    &.hide {  
      .title-hide-box {
        transform: translateY(-96px);
      }
    }
  }
}

@media (max-width: 496px) {
  .products__container-title{
    height: 124px;
  
    &.hide {  
      .title-hide-box {
        transform: translateY(-124px);
      }
    }
  }
}

@media (max-width: 436px) {
  .products__container-title{
    height: 148px;
  
    &.hide {  
      .title-hide-box {
        transform: translateY(-148px);
      }
    }
  }
}

@media (max-width: 275px) {
  .products__container-title{
    height: 220px;
  
    &.hide {  
      .title-hide-box {
        transform: translateY(-220px);
      }
    }
  }
}