

.info__archive {
    padding-bottom: 16px;
}

.info-archive__wrapper {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    position       : relative;

    .upload__content {
        cursor: pointer;
    }

    .info-archive__text {
        line-height: 20px;
        font-family: NotoSemiBold;
        margin-left: 28px;
        font-size  : 14px;
    }

    .info-archive__close {
        cursor  : pointer;
        position: absolute;
        bottom  : 3px;
        right   : 0;
    }

    .info-archive__upload {
        position: absolute;
        bottom  : 3px;
        left    : 0;
    }
}