@use "../../../../../app/styles/variables" as v;

.sales-chart__container {
    display       : flex;
    flex-direction: column;
    border        : 1px solid v.$pc-gray-200;
    border-radius : 12px;
    padding       : 24px;
    width         : 100%;
    height: 498px;

    .title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;

        h4 {
            font-family: NotoBold;
            color      : v.$pc-gray-900;
        }

        .date-range__container {
            display    : flex;
            gap        : 16px;
            align-items: center;

            .date__range {
                color: v.$pc-accent-700;
            }

            .date__button {
                padding: 10px;

                .button__container-icon {
                    margin: 0;
                }
            }
        }
    }

    .horizontal-buttons {
        display: flex;
        padding: 3px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid v.$pc-gray-100;
        background: v.$pc-gray-50;
        width: fit-content;

        .unactive {
            background-color: transparent;
            border: none;
        }
    }

    .sales-chart {
        margin-top: 4px;
    }
}