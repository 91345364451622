@use "../../../../../app/styles/variables" as v;

.most-tables__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 298px;
    gap: 16px;

    .most-table {
        display: flex;
        flex-direction: column;
        border: 1px solid v.$pc-gray-200;
        border-radius: 12px;
        padding: 24px;
        padding-top: 22px;
        width: inherit;

        .title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            margin-bottom: 16px;

            h4 {
                font-family: NotoBold;
                color      : v.$pc-gray-900;
            }

            .featured-icon {
                width           : 32px;
                height          : 32px;
                min-width       : 32px;
                border-radius   : 50%;
                background-color: v.$pc-accent-100;
                display         : flex;
                align-items     : center;
                justify-content : center;

                img {
                    width : 16px;
                    height: 16px;
                }
            }
        }

        .admin__table {
            width: 100%;
            background   : v.$white;
        }

        .admin__table-row {
            justify-content: space-between;
            border: none;
            height: 68px;
            cursor: auto;
    
            &:hover {
                background-color: transparent !important;
            }
        }
    
        .product-name {
            min-width: 205px;
            width    : 205px;
            padding  : 6px 0;
    
            .modal-image {
                .modal__children {
                    margin-right: 12px;
                    min-width: 56px;
                    max-width: 56px;
                    height: 56px;
                    border-radius: 4px;
                    border: 1px solid v.$pc-gray-200;
                    overflow: hidden;
    
                    img {
                        width: 100%;
                        height: 56px;
                        object-fit: cover;
                    }
                }
            }
    
            .product-title {
                overflow: hidden;
                max-height: 50px;
            }
    
            h5 {
                line-height  : 24px;
                color        : v.$pc-gray-900;
                overflow     : hidden;
                text-overflow: ellipsis;
                text-wrap    : nowrap;
            }
    
            h6 {
                line-height: 20px;
                color      : v.$pc-gray-500;
            }
        }

        .product-value {
            display: flex;
            align-self: flex-start;
            justify-content: flex-end;
            width    : max-content;
            padding  : 12px 0;
            padding-left: 24px;

            h5 {
                line-height  : 24px;
                color        : v.$pc-gray-900;
            }
        }
    }
}
