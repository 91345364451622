@use "../../app/styles/variables" as v;

.cart__item {
    padding      : 24px 0;
    border-bottom: 1px solid v.$pc-gray-200;

    &:last-child {
        border: none;
        padding-bottom: 16px; 
    }

    &:first-child {
        padding-top: 16px; 
    }

    .cart__img {
        width       : 296px;
        height      : 126px;
        overflow    : hidden;
        margin-right: 16px;

        img {
            max-width : 100%;
            max-height: 100%;
        }
    }

    .price_mobile {
        display: none;
    }

    .cart__item-description {
        flex-grow: 1;

        .avatar__wrapper {
            button {
                padding: 0;
            }
        }

        .author__wrapper {
            h6 {
                color: v.$pc-gray-700;
            }
        }

        .cart-item__container {
            margin-bottom: 8px;

            .license_line, .info__filestype {
                margin: 0;

                .tooltip__children {
                    top: 3px;
                }

                .tooltipe__container {
                    right: -321px;
                }

                .tooltip__ancor {
                    left: 50%;
                }
            }
        }

        .price {
            margin-bottom: 8px;

            .link{
                font-size: 14px;
            }
        }

        .cost {
            line-height: 28px;
            color      : v.$pc-gray-900;
        }

        .trash__icon {
            cursor: pointer;
        }

        .author__name {
            color: v.$pc-gray-900;
        }

        .avatar__wrapper {
            margin-right: 10px;
        }
    }
}

@media (max-width: 1065px) {
    .cart__item {
        .cart__item-description {
            .cart-item__container {
                .license_line {
                    .tooltipe__container {
                        right: -280px;
                    }

                    .tooltip__ancor {
                        left: calc(50% + 41px);
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .cart__item {
        flex-direction: column;

        .cart__item-description {
            position: relative;
            .price {
                display: none;
            }
        }

        .price_mobile {
            display: flex;
            margin-bottom: 8px;

            .author__name {
                color: v.$pc-gray-700;
            }

            .cost {
                color: v.$pc-gray-900;
                line-height: 28px;
            }
        }

        .cart__img {
            width: 100%;
            height: 160px;
            margin-bottom: 12px;
            margin-right: 0;
        }

        .license_line {
            .info__header {
                line-height: 24px;
            }

            .info__content {
                line-height: 24px;
            }

            .tooltip__children {
                display: none;
            }
        }

        .info__filestype {
            .info__header, .info__content {
                line-height: 24px;
            }
        }

        .cart-item__trash {
            position: absolute;
            bottom: 9px;
            right: 0;
            width: 20px;
            height: 20px;
        }
    }
}
