@use "../../../app/styles/variables" as v;

.profile__settings {
    margin-top      : 51px;
    background-color: v.$pc-gray-50;

    .main__container {
        margin-bottom: 96px;
    }

    .profile__header {
        flex-direction: column;
        align-items   : center;
    }

    .profile__banner {
        overflow       : hidden;
        width          : 100%;
        height         : 190px;
        justify-content: center;

        img {
            width: 2447px;
        }
    }

    .profile__header-wrapper {
        position     : relative;
        top          : -37px;
        width        : calc(100% - 160px);
        max-width    : 1280px;
        padding      : 0px 32px;
        align-items  : flex-start;
        margin-bottom: -5px;

        .avatar__wrapper {
            margin-right: 24px;

            span {
                font-size: 57px;
            } 
        }

        .profile__header-content {
            width: inherit;
        }

        .profile__header-info {
            flex-direction: column;
            margin-right  : 16px;
            padding-top   : 64px;

            h2 {
                font-size    : 30px;
                font-family  : NotoMedium;
                color        : v.$pc-gray-900;
                line-height  : 38px;
                margin-bottom: 4px;
            }
        }
    }

    .profile__buttons-block {
        margin-top: 64px;
        height    : 40px;
        gap       : 12px;

        .secondary-gray {
            padding: 10px 15px;
        }
    }

    .profile__settings-container {
        display      : flex;
        padding      : 52px 32px 32px;
        align-items  : center;
        border-radius: 12px;
        border       : 1px solid v.$pc-gray-200;
        background   : v.$white;
        box-shadow   : 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .profile__settings-form {
        display       : flex;
        width         : 600px;
        flex-direction: column;
        align-items   : flex-start;
    }

    .input__container, .antd-dropdown__container {
        width : 100%;
        height: 46px;
    }

    .profile__name-block {
        display       : flex;
        width         : 100%;
        padding-bottom: 20px;
        gap           : 24px;
    }

    .profile__address-container,
    .profile__input-block {
        width         : 100%;
        padding-bottom: 20px;
    }

    .profile__address-container {
        .profile__input {
            &.error-row {
                .input__children {
                    .icon__left-icon {
                        display: none;
                    }
                }
                .tooltip__children {
                    .tooltipe__container {
                        right: 4px;
                        top: -181px;
                    }

                    .help__icon {
                        right: 15px;
                        top: -57px;
                    }
                }

            }
        }
    }

    .profile__activated-email {
        display: flex;

        button {
            min-width : 152px;
            max-width : 152px;
            max-height: 42px;
            margin-bottom: 16px;
            margin-right : 16px;
        }

        .title {
            display    : flex;
            align-items: flex-start;
            gap        : 2px;
            margin-bottom: 2px;

            h5{
                color      : v.$pc-gray-700;
                font-family: NotoMedium;
            }

            .check-icon {
                height: 16px;
                margin: 6px;
            }
        }

        h5 {
            line-height  : 24px;
        }
    }

    .profile__avatar-block {
        padding-bottom: 20px;

        label {
            margin-bottom: 6px !important;
            color        : #344054;
            font-family  : NotoMedium;
            line-height  : 20px;
            font-size    : 14px;
        }

        .profile__avatar-uploader {
            .avatar__wrapper {
                margin-right: 20px;
                min-width   : 64px;
            }

            .uploader-wrapper {
                width        : 516px;
                height       : 126px;
                border-radius: 8px;
                border       : 1px solid v.$pc-gray-200;
                background   : v.$white;

                padding       : 16px 24px;
                flex-direction: column;
                align-items   : center;
                align-self    : stretch;
            }

            .item-feature__container {
                margin-bottom: 12px;
            }

            span {
                font-size  : 12px;
                line-height: 18px;
            }

            .avatar__wrapper {
                span {
                    font-size: 23px;
                }
            }
        }
    }

    .profile__role-container {
        width: 100%;
    }

    .profile__notifications {
        line-height: 20px;
        align-items: flex-start;

        .profile__notifications-content {
            width: 356px;
        }

        .content-title {
            font-family: NotoMedium;
            color      : v.$pc-gray-700;
        }
    }

    .mb-43 {
        margin-bottom: 43px;
    }

    .divider-end {
        width        : 100%;
        margin       : 24px 0 16px;
        border-bottom: 1px solid v.$pc-gray-200;
    }
}

@media (max-width: 1065px) {
    .profile__settings {
        .profile__header-wrapper {
            top            : -53px;
            width          : 100%;
            justify-content: space-between;
            margin-bottom  : -21px;
        }

        .main__container {
            margin-bottom: 80px;
        }
    }
}

@media (max-width: 768px) {
    .profile__settings {
        .profile__header-wrapper {
            flex-direction: column;
        }

        .profile__buttons-block {
            margin-top: 16px;
        }

        .profile__settings-container,
        .profile__settings-form,
        .profile__avatar-block {
            width: 100%;
        }

        .profile__name-block,
        .profile__inputs-drop {
            flex-direction: column;
            gap           : 46px;
        }

        .profile__avatar-uploader {
            flex-direction: column;
            gap           : 20px;
        }

        .uploader-wrapper {
            width : 100% !important;
            height: auto !important;
        }

        .profile__activated-email {
            flex-direction: column;
        }
    }
}

@media (max-width: 606px) {
    .profile__settings {
        margin-top: 52px;
        
        .profile__banner {
            height         : 96px;
        }

        .profile__header-wrapper {
            top          : -21px;
            padding      : 0px 16px;
            margin-bottom: 11px;

            .avatar__wrapper {
                min-width: 96px;
                width    : 96px;
                height   : 96px;

                span {
                    font-size  : 36px;
                    line-height: 44px;
                } 
            }

            .profile__header-info {
                padding-top: 16px;
                align-self : center;

                h2 {
                    font-size    : 24px;
                    line-height  : 32px;
                    overflow-wrap: anywhere;
                }
            }
        }

        .profile__notifications 
        .profile__notifications-content {
            width: auto;
        }

        .main__container {
            margin-bottom: 64px;
        }
    }
}
