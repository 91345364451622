@use "../../app/styles/variables" as v;

.info__viewmore {
    background : #fff;
    color      : v.$pc-accent-700;
    cursor     : pointer;
    display    : inline;
    white-space: nowrap;
    font-size  : 16px;
    line-height: 24px;

    .info__dots {
        color        : v.$pc-gray-500;
        padding-left: 5px;
    }
}