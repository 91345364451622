@use "../../../app/styles/variables" as v;

/********************** join ************************/
.join__container {
  padding: 0 32px 96px;
}

.join__content {
  background: #f5f8ff;
  padding: 64px 32px;
  border-radius: 8px;
  align-items: baseline;

  h1 {
    text-align: center;
    line-height: 44px;
    margin-bottom: 20px;
    color: v.$pc-gray-900;
    font-family: NotoSemiBold;
  }

  h3 {
    text-align: center;
    line-height: 30px;
  }
}

.join__wrapper {
  max-width: 768px;
  width: 100%;
}

@media (max-width: 1280px) {
  .join__content {
    flex-direction: column;
  }

  .join__wrapper {
    max-width: 100%;

    h1 {
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 1065px) {
  .join__container {
    padding: 80px 24px;
  }
  .join__content {
    padding: 48px 24px;

    .link.xxl {
      line-height: 24px;
      font-size: 16px;
      padding: 12px 20px;
      min-width: auto;
    }
  }
}

@media (max-width: 606px) {
  .join__container {
    padding: 64px 16px;
  }
  .join__content {
    padding: 48px 20px;
  }

  .join__wrapper {
    h1 {
      margin-bottom: 16px;
    }
    h3 {
      margin-bottom: 32px;
    }
  }
}
