@use "../../../app/styles/variables" as v;

.button {
    border-radius: 8px;
    padding      : 8px 12px 8px 12px;
    text-align   : center;
    cursor       : pointer;
    font-family  : NotoSemiBold;
    display      : flex;
    align-items  : center;

    .button__img {
        width : 100%;
        height: 100%;
    }

    /** SIZE **/
    &.xs {
        line-height: 20px;
        font-size  : 14px;
        padding    : 8px 12px;
    }

    &.sm {
        line-height: 20px;
        font-size  : 14px;
        padding    : 8px 14px;
    }

    &.md {
        line-height: 20px;
        font-size  : 14px;
        padding    : 10px 16px;
    }

    &.lg {
        line-height: 24px;
        font-size  : 16px;
        padding    : 10px 18px;

        .button__container-icon{
            width : 20px;
            height: 20px;
            margin-right: 8px;
        }
    }

    &.xl {
        line-height: 24px;
        font-size  : 16px;
        padding    : 12px 20px;
    }

    &.xxl {
        line-height: 28px;
        font-size  : 18px;
        padding    : 16px 28px;
    }

    &.disabled {
        .button__img {
            filter: opacity(0.3);
        }
    }

    /**THEME**/
    /** PRIMARY BUTTON */
    &.primary {
        background: v.$pc-accent-600;
        color     : v.$white;

        &:hover {
            background-color: v.$pc-accent-700;
        }
        &:active {
            background-color: v.$pc-accent-600;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-accent-100;
        }
        &.disabled {
            background-color: v.$pc-accent-200;
            pointer-events  : none;
        }
    }

    &.primary-danger {
        background: v.$pc-error-600;
        color     : v.$white;
        &:hover {
            background-color: v.$pc-error-700;
        }
        &:active {
            background-color: v.$pc-error-600;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-error-100;
        }
        &.disabled {
            background-color: v.$pc-error-200;
            pointer-events  : none;
        }
    }

    /** SECONDARY BUTTON */
    &.secondary {
        background: v.$pc-accent-50;
        color     : v.$pc-accent-700;

        &:hover {
            background-color: v.$pc-accent-100;
        }
        &:active {
            background-color: v.$pc-accent-50;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-accent-100;
        }
        &.disabled {
            background-color: v.$pc-accent-25;
            color           : v.$pc-accent-300;
            pointer-events  : none;
        }
    }

    &.secondary-danger {
        background: v.$pc-error-50;
        color     : v.$pc-error-700;

        &:hover {
            background-color: v.$pc-error-100;
        }
        &:active {
            background-color: v.$pc-error-50;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-error-100;
        }
        &.disabled {
            background-color: v.$pc-error-25;
            color           : v.$pc-error-300;
            pointer-events  : none;
        }
    }

    &.secondary-gray {
        border    : 1px solid v.$pc-gray-300;
        background: v.$white;
        color     : v.$pc-gray-700;

        &:hover {
            background-color: v.$pc-gray-50;
        }
        &:active {
            background-color: v.$white;
            box-shadow       : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-gray-100;
        }
        &.disabled {
            background-color: v.$white;
            border          : 1px solid v.$pc-gray-200;
            color           : v.$pc-gray-300;
            pointer-events  : none;

            .button__img {
                filter: opacity(25%);
            }
        }
    }

    &.secondary-gray-danger {
        background: v.$white;
        border    : 1px solid v.$pc-error-300;
        color     : v.$pc-error-500;

        &:hover {
            background-color: v.$pc-error-50;
        }
        &:active {
            background-color: v.$white;
            box-shadow      : 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px v.$pc-error-100;
        }
        &.disabled {
            background-color: v.$white;
            border          : 1px solid v.$pc-error-300;
            color           : v.$pc-error-300;
            pointer-events  : none;
        }
    }

    /** TERTIARY BUTTON */
    //&.tertiary {}

    &.tertiary-danger{
        background: v.$white;
        color     : v.$pc-error-700;

        &:hover {
            background-color: v.$pc-error-50;
        }
        &:active {
            background-color: v.$white;
        }
        &.disabled {
            background-color: v.$white;
            color           : v.$pc-error-300;
            pointer-events  : none;

            img {
                opacity: .5;
            }
        }
    }

    &.tertiary-gray {
        background-color: transparent;
        color     : v.$pc-gray-500;
        //padding   : 18px 10px;

        &:hover {
            color           : v.$pc-gray-600;
            background-color: v.$pc-gray-50;
        }
        &:active {
            color : v.$pc-gray-500;
        }
        &.disabled {
            color         : v.$pc-gray-300;
            pointer-events: none;

            img {
                opacity: .5;
            }
        }
    }

    //&.tertiary-gray-danger {}

    /** TEXT BUTTON (LINK)*/
    &.link-color {
        background-color: transparent;
        color     : v.$pc-accent-700;
        padding   : 0 6px;

        &:hover {
            color : v.$pc-accent-800;
        }
        &:active {
            color : v.$pc-accent-700;
        }
        &.disabled {
            color         : v.$pc-gray-300;
            pointer-events: none;

            img {
                opacity: .5;
            }
        }
    }

    &.link-gray {
        background-color: transparent;
        color     : v.$pc-gray-500;
        padding   : 0 6px;

        &:hover {
            color : v.$pc-gray-600;
        }
        &:active {
            color : v.$pc-gray-500;
        }
        &.disabled {
            color         : v.$pc-gray-300;
            pointer-events: none;

            img {
                opacity: .5;
            }
        }
    }
    
    &.link-danger {
        background-color: transparent;
        color     : v.$pc-error-700;
        padding   : 0 6px;

        &:hover {
            color : v.$pc-error-800;
        }
        &:active {
            color : v.$pc-error-800;
        }

        &.disabled {
            color         : v.$pc-error-300;
            pointer-events: none;

            img {
                opacity: .5;
            }
        }
    }

    /** Horizontal Tabs Button */

    &.horizontal-gray {
        font-family     : NotoMedium;
        background-color: transparent;
        color           : v.$pc-gray-500;
        border-radius   : 6px;

        &.active {
            background: v.$white;
            color     : v.$pc-gray-700;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
        }
        &:hover {
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
            background: v.$pc-gray-25;
            color     : v.$pc-gray-700;
        }

        &:active {
            color     : v.$pc-gray-700;
            background: v.$pc-gray-50;
            box-shadow: 0px 0px 0px 4px v.$pc-gray-100, 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
        }
    }
}
