@use "../../../app/styles/variables" as v;

.user-info__wrapper {
    height: 100%;
    overflow-y: scroll;
    max-width: 312px;
    min-width: 312px;
}

.panel-info__wrapper {
    padding: 0 32px;

    .guffy__title {
        color: v.$pc-gray-700;
        margin-top: 16px;
        margin-bottom: 4px;
        line-height: 20px;
        font-family: NotoMedium;
    }

    .info__tittle {
        color: v.$pc-gray-700;
        margin-top: 16px;
        margin-bottom: 4px;
        line-height: 20px;
        padding: 10px 16px 12px 0;
        font-family: NotoMedium;
    }

    .info__suptittle {
        line-height: 20px;
        padding-bottom: 16px;
    }

    .info__license {
        padding-bottom: 16px;
    }

    .info__steps {
        margin-bottom: 4px;
    }

    .user__info-license {
        margin-bottom: 16px;

        .info__tittle {
            margin-bottom: 0;
        }

        .badge__container {
            margin-bottom: 16px;
            margin-top: 0;

            &.blue-gray {
                margin-bottom: 0;
            }
        }
    }

    .radio__container {
        align-items: flex-start;

        .radio__content {
            h6 {
                font-family: NotoMedium;
                color: v.$pc-gray-700;
                line-height: 20px;
            }

            span {
                line-height: 20px;
            }
        }
    }

    .user__info-team {
        padding-bottom: 16px;

        .avatar-label__container {
            margin-right: 16px;
            margin-left: 16px;
        }

        .info__title {
            padding: 10px 0 16px 0;
            line-height: 20px;
            color: v.$pc-gray-700;
            font-family: NotoMedium;
            margin-top: 16px;
        }

        .avatar__wrapper {
            width: 40px;
            height: 40px;
        }
    }

    .user__info-license-descr {
        line-height: 20px;
        color: v.$pc-gray-500;
        margin-top: 8px;
        margin-bottom: 16px;

        a {
            color: v.$pc-accent-700;
            font-family: NotoRegular
        }
    }

    .avatar-label__container {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        .author__name {
            color: v.$pc-gray-700;
        }
        .author__text{
            text-transform: lowercase;
            font-family: NotoRegular;

            &::first-letter {
                text-transform: capitalize;
                }
        }
    }

    .info__delete {
        padding: 16px 0;

        .button {
            justify-content: flex-start;
            padding: 10px 0;
        }
    }
}

@media (max-width: 1028px) {
    .user-info__wrapper {
        display: none;
    }
}
