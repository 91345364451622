@use "../../../app/styles/variables" as v;

.admin__info-menu {
    .admin-menu__item {
        cursor     : pointer;
        line-height: 20px;
        color      : v.$pc-gray-500;
        font-family: NotoSemiBold;
        background : transparent;
    }
}
