@use "../../../app/styles/variables" as v;

.projects__panel {
    width       : 523px;
    padding-top : 12px;
    padding-left: 10px;
    overflow-y  : clip;

    .admin__table {
        width: 100%;
    }

    .admin__table-body {
        height: calc(100dvh - 153px);
    }
    
    .admin__table-row {
        padding: 6px;
        height : 68px;

        &.current {
            background: v.$pc-accent-100!important;
        }

        &.deleted {
            background: v.$pc-error-100;
        }
    }

    .project-name {
        min-width    : 212px;
        max-width    : 212px;
        padding-right: 16px;

        .project-name__icon__wrapper {
            width        : 56px;
            min-width    : 56px;
            height       : 56px;
            border-radius: 4px;
            border       : 1px solid v.$pc-accent-300;
            background   : v.$pc-accent-300;

            .icon-ava__img {
                width     : 100%;
                height    : 54px;
                object-fit: cover;
            } 
        }

        .user-project-licennses__icon {
            position     : absolute;
            bottom       : -9px;
            right        : -7px;

            .icon-ava__img {
              width           : 21px;
              height          : 21px;
              padding         : 1px;
              background-color: v.$sc-blue-gray-500;
              border          : 1px solid v.$white;
              border-radius   : 50%;
            } 
        }

        .project-title {
            overflow    : hidden;
            padding-left: 12px;

            .project-status {
                position: relative;
                display : flex;

                .status__container {
                    position: absolute;
                    right   : 0;
                }
            }
        }

        h5 {
            line-height  : 24px;
            color        : v.$pc-gray-900;
            font-family  : NotoMedium;
            text-overflow: ellipsis;
            overflow     : hidden; 
            white-space  : nowrap;
            padding-right: 10px;
            width        : 170px;
        }

        .project-id {
            font-family: NotoRegular;
            color      : v.$pc-gray-500;
        }
    }

    .project-customer {
        max-width  : 212px;
        min-width  : 212px;
        padding    : 10px 16px;
        
        h5 {
            line-height  : 24px;
            font-family  : NotoMedium;
            color        : v.$pc-gray-900;
            overflow-x   : hidden;
            white-space  : nowrap;
            text-overflow: ellipsis;
        }

        h6 {
            line-height  : 20px;
            color        : v.$pc-gray-500;
            overflow-x   : hidden;
            white-space  : nowrap; 
            text-overflow: ellipsis;
        }
    }

    .project-notification{
        min-width  : 60px;
        max-width  : 60px;
        padding    : 16px;
        padding-right: 10px;
        display    : flex;
        align-items: center;
    }

    .not-found-message {
        display: none;
    }
}

@media (max-width: 1027px) {
    .projects__panel {
        .project-customer {
            display: none;
        }
    }
}

@media (max-width: 670px) {
    .projects__panel {
        .admin__table-body {
            height: calc(100dvh - 210px);
        }

        .project-customer {
            display: block;
        }

        .not-found-message {
            display: flex;
        }
    }
}

@media (max-width: 606px) {
    .projects__panel {
        .admin__table-body {
            height: calc(100dvh - 186px);
        }
    }
}

@media (max-width: 516px) {
    .projects__panel {
        .project-customer {
            display: none;
        }
    }
}

