@use "../../../../../app/styles/variables" as v;

.total-chart__container {
    display        : flex;
    justify-content: space-between;
    width          : 100%;
    height         : fit-content;
    gap            : 16px;

    .total__item {
        display       : flex;
        flex-direction: column;
        border        : 1px solid v.$pc-gray-200;
        border-radius : 12px;
        padding       : 24px;
        width         : inherit;
        font-family   : NotoMedium;

        h6 {
            color: v.$pc-gray-700;
        }

        h2 {
            color: v.$pc-gray-900;
        }

    }
}