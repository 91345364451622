@use "../../../app/styles/variables" as v;

.admin-navigate {
    padding: 0px 32px 0 20px;
    border-bottom: 1px solid v.$pc-gray-100;

    .admin-navigate__menu {
        align-items: end;
        padding-top: 16px;
        min-width  : 502px;

        .admin-navigate__tab {
            gap: 16px;
            height: 38px;
        }

        .button__container-icon {
            width: 44px !important;
            height: 44px !important;
            margin: 0 !important;
            padding: 12px !important;
    
            img {
                width: 20px;
                height: 20px;
            }
        }

        .link-gray {
            padding: 0;
        }

        .refresh-page {
            &:active {
                .button__container-icon {
                    border-radius: 50%;
                    box-shadow:
                        0px 0px 0px 4px #f2f4f7,
                        0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                }
            }
        }
    }

    .admin-navigate__search {
        //margin-bottom: 12px;
        align-items: center;
        width      : 100%;
        justify-content: flex-end;

        .date-range__container {
            display    : flex;
            gap        : 16px;
            align-items: center;

            .date__range {
                color: v.$pc-accent-700;
            }

            .date__button {
                padding: 10px;

                .button__container-icon {
                    margin: 0;
                }
            }
        }

        .search__container_withbg {
            display: flex;
            margin: 0 6px;
            height: 44px;
            padding: 8px 0;
            background: v.$pc-gray-50;
            max-width: 320px;
            border  : 1px solid v.$pc-gray-200;
            align-items: center;


            .input__line {
                box-shadow   : none;
            }

            input {
                box-shadow   : none;
                border       : none;
            }

            .button {
                right : 25px;
                bottom: 8px;
            }
        }

        button.secondary-gray {
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
    }    
}

@media (max-width: 1027px) {
    .admin-navigate {
        .admin-navigate__menu {
            min-width: 328px;
        }

        .refresh-page {
            display: none;
        }
    }
}

@media (max-width: 670px) {
    .admin-navigate {
        flex-direction: column-reverse;
        padding: 0;

        .admin-navigate__menu {
            padding: 12px 16px 8px;

            .admin-navigate__tab {
                height: 30px;

                a.tab-link {
                    font-size  : 14px;
                    line-height: 20px;

                    &.active {
                        text-underline-offset: 13px;
                    }
                }
            }
        }

        .admin-navigate__search {
            border-bottom: 1px solid v.$pc-gray-200;
            padding      : 3px 16px;
            .search__container_withbg {
                max-width   : none;
                margin      : 0;
                margin-right: 6px;

                .button {
                    bottom: 8px;
                }
            }
        }
    }
}