@use "../../../app/styles/variables" as v;

.mobile-user-panel {
    margin: 34px 16px 64px;

    .mobile-user-panel__header {
        margin-bottom: 48px;
        .title {
            font-family  : NotoBold;
            font-size    : 30px;
            color        : v.$pc-gray-900;
            line-height  : 38px;
            margin-bottom: 16px;
        }
        .sub-title {
            line-height: 28px;
        }
    }

    .mobile-user-panel__banner {
        display        : flex;
        justify-content: center;

        img {
            width: 278px;
        }
    }
}