@use "../../../app/styles/variables" as v;

.payment-status__success {
    display            : flex;
    flex-direction     : column;
    padding            : 64px 0;
    opacity            : 0;
    animation-fill-mode: forwards;
    animation-delay    : 0.9s;
    animation-name     : showon;

    .payment-status__header {
        max-width    : 740px;
        align-items  : flex-start;
        margin-bottom: 64px;

        .sub__title {
            font-family: NotoBold;
            line-height: 24px;
            color      : v.$pc-accent-700;
        }

        .title {
            font-family: NotoSemiBold;
            font-size  : 36px;
            line-height: 44px;
            color      : v.$pc-gray-900;
        }

        .sup__title {
            font-size    : 20px;
            line-height  : 30px;
        }
    }

    .payment-status__content {
        display      : flex;
        align-items  : center;
        gap          : 98px;

        .feature__content {
            width: 618px;
        }

        .feature__item {
            display       : flex;
            align-items   : flex-start;
            gap           : 16px;
            max-width     : 560px;
            min-height    : 86px;
            margin-bottom : 48px;
        
            .icon {
                margin-top: 2px;
            }
        
            img {
                width : 24px;
                height: 24px;
            }
        
            &:last-child {
                margin-bottom: 0;
            }
        }
    
        .feature__item-content {
            gap    : 8px;
            display: grid;
        
            .title {
                font-family: NotoSemiBold;
                color      : v.$pc-gray-900;
                line-height: 30px;
            }
            
            .description {
                color      : v.$pc-gray-500;
                line-height: 24px;
            }
        }

        .image-container {
            display         : flex;
            position        : relative;
            width           : 500px;
            padding-top     : 58px;
            justify-content : center;
            align-items     : center;

            .image-background {
                display         : flex;
                width           : 500px;
                height          : 442px;
                padding         : 0px 95px 0px 94px;
                justify-content : center;
                align-items     : center;
                flex-shrink     : 0;
                border-radius   : 16px;
                background      : v.$pc-gray-100;
            }

            img {
                position: absolute;
                top: 0;
                margin: auto;
                width : 311px;
                height: 500px;
            }
        }
    }
}

@keyframes showon {
    to {
        opacity: 1;
    }
}

@media (max-width: 1065px) {
    .payment-status__success {
        padding    : 48px 0;
        align-items: center;

        .payment-status__header {
            max-width: 720px;
            margin-bottom: 48px;

            .title {
                font-size    : 30px;
                line-height  : 38px;
                margin-bottom: 20px;
            }
        }

        .payment-status__content {
            flex-direction: column;
            width         : 720px;
            gap           : 112px;

            
            .feature__content {
                position: relative;
                left    : -52px;
            }

            .image-container {
                .image-background {
                    width: 720px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .payment-status__success {
        align-items: baseline;
        padding-top: 24px;

        .payment-status__header {
            max-width: 720px;
            margin-bottom: 48px;
        }

        .payment-status__content {
            width: 100%;
            gap  : 42px;

            .feature__content {
                position: static;
                width   : 100%;

                .title {
                    font-size  : 18px;
                    line-height: 28px;
                }
            }

            .image-container {
                width: 100%;
                padding-top: 38px;

                .image-background {
                    width : 100%;
                    height: 310px;
                }

                img {
                    width : 216px;
                    height: 348px;
                }
            }
        }
    }
}
