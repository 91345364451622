@use "../../../app/styles/variables" as v;

.steps__container {
    margin-left   : 16px;
    padding-bottom: 19px;

    .tooltip__children {
        cursor: pointer;

        .tooltipe__container {
            width   : 244px;
            left    : -6px;
        }

        .cost_milestone {
            font-size    : 14px;
            line-height  : 20px;
            font-family  : NotoSemiBold;
            color        : v.$pc-accent-700;
        }

        .cost_milestone__descr {
            font-size    : 14px;
            line-height  : 20px;
            margin-top   : 4px;
        }
    }

    .milestone_tooltip__title {
        line-height  : 20px;
        color        : v.$pc-gray-700;
        font-family  : NotoSemiBold;
    }

    .content_milestone {
        display: flex;

        &:first-child {
            margin-top: 4px;
        }
    }


    .time_milestone {
        font-size  : 14px;
        line-height: 20px;
        margin-left: 5px;
    }

    .hours_milestone {
        color        : v.$pc-gray-900;
        font-size  : 14px;
        line-height: 20px;
    }
}

.step-duration_container {
    width          : 24px;
    display        : flex;
    justify-content: center;

    .step-duration {
        height    : 20px;
        width     : 4px;
        background: v.$pc-gray-100;

        &.passed {
            background   : v.$pc-accent-600;
            border-radius: 0 0 2px 2px;
            margin-bottom: -2px;
            height       : 22px;
            z-index      : 2;
        }
    }
}

.step-milestone__container {
    position: relative;

    .step-milestone__description {
        position: absolute;
        top     : 0;
        left    : 36px;
    }

    .step-milestone__title {
        color      : v.$pc-gray-300;
        font-family: NotoMedium;
        line-height: 20px;
        white-space: nowrap;

        &.passet {
            color: v.$pc-gray-700;
        }
    }

    .step-milestone__suptitle {
        line-height: 20px;
        color      : v.$pc-gray-300;
        white-space: nowrap;

        &.passet {
            color: v.$pc-gray-500;
        }
    }

    .step-milestone {
        width          : 24px;
        height         : 24px;
        border-radius  : 50%;
        background     : v.$pc-gray-100;
        border         : 2px solid v.$pc-gray-100;
        display        : flex;
        align-items    : center;
        justify-content: center;
        position       : relative;

        &.notextrimе {
            margin-top: 20px;

            &::after {
                content   : "";
                display   : block;
                width     : 4px;
                background: v.$pc-gray-100;
                position  : absolute;
                bottom    : 108%;
                height    : 20px;
            }
        }

        &.passed {
            border    : 2px solid v.$pc-accent-600;
            background: v.$white;

            &::after {
                background: v.$pc-accent-600;
            }
        }

        img {
            width : 14px;
            height: 14px;
        }
    }
}
