@use "../../../app/styles/variables" as v;

.best__container {
  background: v.$pc-gray-100;
}

.best__content {
  height: 738px;
  justify-content: space-between;
}

.best__left {
  width: 571px;
  color: v.$pc-gray-900;

  h1 {
    line-height: 44px;
    font-family: NotoSemiBold;
  }

  a {
    max-width: fit-content;
  }
}

.best__right {
  height: 525px;

  .best__banner {
    height: 100%;
  }
}

@media (max-width: 1200px) {
  .best__content {
    flex-direction: column;
    padding: 80px 0;
    height: 818px;
  }

  .best__left {
    max-width: 720px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
      font-size: 30px;
      line-height: 38px;
    }
  }

  .best__right {
    height: 448px;
  }
}

@media (max-width: 650px) {
  .best__content {
    padding: 65px 0;
    height: auto;
    gap: 24px;
  }

  .best__left {
    h1 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }

  .best__right {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: self-end;

    .best__banner {
      width: 100%;
    }
  }
}
