@use "../../../app/styles/variables" as v;

.antd-dropdown__container {
    width: 100%;

    .ant-select {
        width : 100%;

        /* SIZE */
        &.ant-select-lg {
            height: 46px;
        }

        .ant-select-selector {
            border: 1px solid v.$pc-gray-300;

            .ant-select-selection-search-input {
                font-size  : 16px;
                line-height: 24px;
            }
        }

        .input-search__item {
            display    : flex;
            align-items: center;
            overflow   : hidden;

            .icon-ava__img {
                filter: opacity(1);
                transition: all 0.3s, visibility 0s;
                margin-right: 8px;
                max-width: 20px;
            }
        }

        .ant-select-arrow {
            img {
                transform: rotate(90deg);
                width    : 9px;
            }
        }

        .ant-select-selection-item {
            .input-search__item {
                pointer-events: none;
            }
        }

        /* MODE MULTIPLE */
        &.ant-select-multiple {
            .ant-select-selection-item {
                background-color: v.$white;
                border: 1px solid v.$pc-accent-300;
                border-radius: 50%;
                justify-content: center;
                width: 32px;
                padding: 2px;

                .ant-select-selection-item-content {
                    align-content: center;
                    margin: 0;

                    .avatar__wrapper {
                        margin: 0;
                    }

                    .author__name {
                        display: none;
                    }
                }

                .ant-select-selection-item-remove {
                    display: none;
                }
            }
        }

        /* IS OPEN */
        &.ant-select-open {
            .ant-select-arrow {
                .search {
                    width: 18px;
                    margin-right: -4px;
                }

                .rotate {
                    transform: rotate(-90deg);
                }
            }
        }

        &.ant-select-focused {
            .ant-select-selector {
                border-color: v.$pc-accent-300 !important;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px v.$pc-gray-100 !important;
            }
        }

        &:hover {
            .ant-select-selector {
                border-color: v.$pc-accent-300 !important;
            }
        }

        &.ant-select-disabled {
            .ant-select-selector {
                background-color: v.$pc-gray-50;
                color: v.$pc-gray-500;

                .icon-ava__img {
                    filter: opacity(0.6);
                }
            }
        }
    }

    &.icon__left {
        .ant-select-open {
            .input-search__item {    
                .icon-ava__img {
                    filter: opacity(0.3);
                    transition: all 0.3s, visibility 0s;
                }
            }
        }
    }
}

/* DROPDOWN LIST IN BODY */
.ant-select-dropdown {
    .input-search__item {
        display: flex;
        align-items: center;

        .icon-ava__img {
            margin-right: 8px;
            max-width: 20px;
        }

        span {
            font-size: 16px;
        }
    }

    .ant-select-item-option-state {
        .icon-ava__img {
            padding: 2px;
        }
    }
}
