@use "../../../app/styles/variables" as v;

.icon-ava {
    line-height    : 20px;
    font-size      : 14px;
    display        : flex;
    border-radius  : 50%;
    height         : 32px;
    width          : 32px;
    background     : v.$pc-accent-50;
    align-items    : center;
    justify-content: center;
    margin-right   : 8px;
    font-family    : NotoMedium;
    color          : v.$pc-accent-700;
}
