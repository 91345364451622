@use "../../../app/styles/variables" as v;

.status__container {
    border-radius: 50%;
    background: v.$pc-success-500;
    border: 3px solid v.$white;

    &.checking{
        background: #ccc;
    }

    &.xs {
        height: 6px;
        width : 6px;
        border: 1px solid #fff;
    }

    &.sm {
        height: 8px;
        width : 8px;
        border: 2px solid #fff;
    }

    &.md {
        height: 10px;
        width : 10px;
        border: 2px solid #fff;
    }

    &.lg {
        height: 12px;
        width : 12px;
        border: 2px solid #fff;
    }

    &.xl{
        height: 14px;
        width : 14px;
        border: 3px solid #fff;
    }

    &.xxl {
        height: 16px;
        width : 16px;
        border: 3px solid #fff;
    }
}