@use "../../../app/styles/variables" as v;

.footer-link__container {
    span {
        display      : inline-block;
        width        : 42px;
        font-family  : NotoMedium;
        font-size    : 12px;
        line-height  : 18px;
        border-radius: 16px;
        margin-left  : 8px;
        text-align   : center;
    }
    
    a {
        font-size  : 16px;
        line-height: 24px;
    }

    /* THEME */
    &.dark-primary {
        span {
            color           : v.$pc-accent-700;
            background-color: v.$pc-accent-50;
        }

        a {
            color: v.$pc-accent-200;

            &:hover {
                color: v.$white;
            }
        }
    }

    &.dark-gray {
        span {
            color           : v.$pc-gray-700;
            background-color: v.$pc-gray-100;
        }

        a {
            color: v.$pc-gray-200;

            &:hover {
                color: v.$pc-gray-50;
            }
        }
    }
}