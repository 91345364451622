@use "../../../app/styles/variables" as v;

.download-free__container-banner {
    padding: 96px 0;
    padding-right: 24px;

    a {
        display: block;
        margin-top: 40px;
        width: 452px;
    }
}

.download-free__banner {
    width: 50%;
    margin-right: 64px;

    .banner_bg {
        height: 512px;
    }

    .banner__perspective {
        position: absolute;
        height: 512px;
        width: 100%;
        background: linear-gradient(120deg, #848FF8 25%, #DCE0FD 80%, #CD6EEF 96%);
    }
    .banner__clip {
        position: absolute;
        right: 0;
        border-right: 270px solid v.$white;
        border-bottom: 512px solid transparent;
    }

    .banner__img {
        position: absolute;
        right: -35px;
        bottom: 0;
        height: 512px;
    }
}

.download-free__title {
    width: 50%;
    max-width: 559px;

    h1 {
        font-size: 48px;
        line-height: 60px;
        font-family: NotoSemiBold;
        color: v.$pc-gray-900;
        margin-bottom: 32px;

        .highlight {
            font-family: NotoSemiBold;
            color: v.$pc-accent-700;
        }
    }
}

.download-free__sup-title {
    margin-left: 16px;

    h4 {
        line-height: 28px;
        color: v.$pc-gray-500;
        margin-left: 19px;
    }
}

@media (max-width: 1140px) {
    .download-free__container-banner {
        padding: 80px 0;
        padding-right: 24px;

        a {
            width: 100%;
        }
    }

    .download-free__title {
        max-width: 400px;

        h1 {
            line-height: 38px;
            font-size: 30px;
            margin-bottom: 20px;
        }
    }

    .download-free__sup-title {
        h4 {
            font-size: 16px;
            line-height: 24px;
        }
        & div {
            margin-top: 16px;
        }
    }

    .download-free__banner {
        margin-right: 48px;

        
        .banner_bg {
            height: 438px;
        }

        
        .banner__perspective {
            height: 438px;
        }

        .banner__clip {
            border-right: 184px solid #fff;
            border-bottom: 438px solid transparent;
        }

        .banner__img {
            height: 400px;
            right: -24px;
        }
    }
}

@media (max-width: 768px) {
    .download-free__container-banner {
        padding-top: 64px;
        padding-bottom: 64px;
        padding-right: 0;
        flex-direction: column;
        align-items: flex-start;
    }

    .download-free__banner {
        width: 64%;
        margin-right: 0;
        margin-bottom: 32px;

        .banner_bg {
            height: 257px;
        }

        
        .banner__perspective {
            height: 257px;
        }

        .banner__clip {
            border-right: 118px solid #fff;
            border-bottom: 257px solid transparent;
        }

        .banner__img {
            height: 257px;
            right: -56px;
        }
    }

    .download-free__title {
        width: 100%;
        padding: 0 24px;
        max-width: none;
        margin-right: 0;
    }
}

@media (max-width: 466px) {
    .download-free__banner {
        width: 100%;

        .banner_bg {
            display: flex;
            justify-content: center;
        }

        .banner__perspective, .banner__clip {
            right: 30%;
        }

        .banner__img {
            position: static;
            z-index: 1;
        }
    }
}

@media (max-width: 315px) {
    .download-free__title {
        word-break: break-all;
    }
}
