@use "../../app/styles/variables" as v;

ul.header__navigation-mobile {
    display: none;
}


    .menu-media {
        top          : 80px !important;
        position     : fixed;
        border-radius: 0;
        box-shadow   : 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        width        : 375px;

        .menu-media__content {
            padding: 24px 0;
        }

        .menu__item:first-child {
            display: none;
            pointer-events: all;
            padding: 0px 16px 4px;
        }

        .menu__item {
            background: none;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            .menu__item-component {
                &:hover {
                    background: v.$pc-gray-50;
                }

                .link {
                    padding: 12px 16px;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: left;
                    color: #344054;
                    font-family: NotoMedium;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }

       

        .header__form-container {
            display: block;
        }
    }

@media (max-width: 1065px) {
    ul.header__navigation-mobile {
        display: flex;
        align-items: center;
        list-style-type: none;

        li {
            .header__logo-icon {
                display: block;
                width: 32px;
                height: 32px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .burger__menu {
        margin-left: 16px;
        cursor: pointer;
    }
}

@media (max-width: 606px) {
    .menu-media {
        top: 56px !important;

        .menu__item:first-child {
            display: block;
        }
    }
}

@media (max-width: 450px) {
    .menu-media {
        width: 100%;
    }

    .menu-default {
        width: 90vw;
        .link, .menu_logout {
            font-size: 16px;
            line-height: 24px;
        }
        .menu__item-component {
            padding: 5px;
        }

        .author__wrapper {
            flex: 1;
        }
    }
}
