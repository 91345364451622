@use "../../app/styles/variables" as v;

.cart-empty__wrapper {
    padding: 0 32px 64px;
}

.cart-empty__container {
    flex-direction: column;
    padding-top   : 64px;

    .title {
        font-family: NotoSemiBold;
        line-height: 44px;
        color      : v.$pc-gray-900;
    }

    .sub__title {
        line-height  : 30px;
        margin-bottom: 36px;
        max-width    : 545px;
    }
}

.cart-empty__showrooms-container {
    margin-top         : 64px;
    padding            : 0;
    opacity            : 0;
    animation-fill-mode: forwards;
    animation-delay    : 0.7s;
    animation-name     : showon;

    .similar__container {
        margin-top: 24px;

        .similar__slider {
            height: 200px;

            .slick-list {
                height: 200px !important;
            }

            .similar__img {
                height: 200px;
            }
        }

        .similar__title {
            line-height: 30px;
            color      : v.$pc-gray-900;
            margin-top : 0;
            font-family: NotoSemiBold;
        }

    }
    
}

@media (max-width: 1065px) {
    .cart-empty__wrapper {
        padding: 0 24px 48px;
    }

    .cart-empty__container {
        padding-top: 48px;

        .title {
            font-size  : 30px;
            line-height: 38px;
        }

        .sub__title {
            max-width: 565px;
        }
    }

    .cart-empty__showrooms-container {
        margin-top: 48px;

        .similar__container {
            margin-top: 20px;
        }
    }
}

@media (max-width: 606px) {
    .cart-empty__wrapper {
        padding: 0 16px 48px;
    }

    .cart-empty__container {
        padding-top: 24px;

        .title {
            margin-bottom: 16px;
        }

        .sub__title {
            font-size    : 18px;
            line-height  : 28px;
            margin-bottom: 24px;
        }
    }

    .cart-empty__showrooms-container {

        .similar__container {
            margin-top: 16px;

            .similar__slider {
                height: 140px;
    
                .slick-list {
                    height: 140px !important;
                }
    
                .similar__img {
                    height: 140px;
                }
            }
        }
    }
}
