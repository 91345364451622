@use "../../../app/styles/variables" as v;

.countries-popup__bkg {
    overflow: auto;
    height  : 100vh;
    z-index : 99;
}

.countries-popup__wrapper {
    padding      : 24px;
    border-radius: 12px;
    box-shadow   : 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    background   : v.$white;
    width        : 100%;
    max-width    : 480px;
    z-index      : 9;
    margin       : 16px;

    .countries-popup__img {
        width        : 100%;
        border-radius: 8px;
    }

    .countries-popup__title {
        margin-top : 20px;
        line-height: 28px;
        font-family: NotoSemiBold;
        color      : v.$pc-gray-900;
    }

    .countries-popup__subtitle {
        margin-top : 8px;
        line-height: 20px;
        color      : v.$pc-gray-500;
    }

    .countries-dropdown__title {
        margin-top   : 20px;
        margin-bottom: 6px;
        line-height  : 20px;
        font-size    : 14px;
        color        : v.$pc-gray-700;
        font-family  : NotoMedium;
        display      : inline-block;
    }

    .countries-popup__actions {
        display   : flex;
        gap       : 12px;
        justify-content: center;
        margin-top: 32px;
        flex-wrap : wrap;

        button {
            width: 210px;
            display: flex;
            justify-content: center;
        }
    }
}

@media (max-width: 480px) {
    .countries-popup__wrapper {
        padding: 20px 16px 16px;

        .countries-popup__title {
            margin-top: 16px;
        }

        .countries-dropdown__title {
            margin-top: 16px;
        }
        
        .countries-popup__actions {
            margin-top: 23px;

            button {
                width: 100%;
            }
        }
    }
}

@media (max-height: 650px) {
    .countries-popup__bkg {
        align-items: baseline;
    }
}
