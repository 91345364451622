@use "../../../app/styles/variables" as v;

.billing__form {
    width  : 100%;
    padding: 0;

    .billing-form__label {
        font-family: NotoMedium;
        position   : absolute;
        top        : -23px;
        line-height: 20px;
        font-size  : 14px;
        color      : v.$pc-gray-700;
    }
    

    .billing-form__row {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        align-items    : baseline;
        cursor         : pointer!important;
        gap            : 32px;

        .billing-form__item {
            width: 50%;

            .tooltip__children {
                width: 100%;
            }
        }

        .antd-dropdown__container {
            width: 50%;

            .input {
                color: v.$pc-gray-500;
            }
        }

        .question__icon {
            position : absolute;
            right    : 14px;
            top      : 50%;
            transform: translateY(-50%);
        }

        .tolltipe__content {
            font-family: NotoSemiBold;
            line-height: 20px;
        }

        .tooltip__children {
            width: 50%;
        }

        .postalcode {
            .input__text-error {
                margin-block-end: -24px;
            }
        }
    }

    .tooltip__children {
        .input__container, .antd-dropdown__container {
            width: 100%;
        }

        .tooltipe__title {
            line-height  : 20px;
            color        : v.$pc-gray-700;
            margin-bottom: 4px;
            font-family  : NotoSemiBold;
        }

        .tooltipe__subtitle {
            line-height: 20px; 
            color      : v.$pc-gray-500;
        }

        .tooltipe__container {
            right : 4px;
            top   : -80px;
            height: fit-content;
        }
    }

    .billing-form__confirm-data-btn {
        margin-top: 24px;
        max-width : 243px;
    }

    .billing-form__row-full {
        &.card {
            margin-top: 40px;
        }

        .checkbox__item {
            margin-right: 6px;
        }

        &.error-checkbox {
            .error {
                margin-left: 34px;
            }

            .checkmark {
                border-color: v.$pc-error-500;
            }
        }

        &.error-row {
            position: relative;

            .icon__left-icon {
                display: none;
            }

            .tooltip__children {
                position: absolute;
                top     : 22%;
                right   : 14px;

                .icon-ava__img  {
                    top  : 0;
                    right: 0
                }

                .tooltipe__container.top {
                    top  : -127px;
                    right: -10px;
                }
            }
        }

        &.legal {
            span {
                margin-left: 8px;
                font-size  : 14px;
                line-height: 20px;
                font-family: NotoMedium;
                color      : v.$pc-gray-700;
            }
        }

        .check__container {
            color       : v.$pc-gray-500;
            font-size   : 16px;
            line-height : 24px;
            padding-left: 32px;
            font-family : NotoRegular;

            .checkmark {
                width : 20px;
                height: 20px;
                margin-top: 2px;
            }
        }

        .switcher__container {
            margin: 0;
        }

        .question__icon {
            position : absolute;
            right    : 14px;
            top      : -31px;
        }

        .tooltip__children {
            width: auto;
        }

        .tooltipe__container{
            &.top {
                right : 3px;
                top   : -155px;
                height: fit-content;
            }
        }

        .pright {
            .input {
                padding-right: 37px;
            }
        }
    }

    .billing-form__country {
        position: relative;

        .question__icon {
            position : absolute;
            right    : 14px;
            top      : 50%;
            transform: translateY(-50%);
        }

        .input-search {
            color: v.$pc-gray-500;
        }
    }

    .tolltipe__title {
        font-size  : 14px;
        line-height: 20px;
        color      : v.$pc-gray-700;
        margin-top : 4px;
        font-family: NotoSemiBold;
    }

    .tolltipe__subtitle {
        font-size  : 14px;
        line-height: 20px;
        color      : v.$pc-gray-500;
        margin-top : 4px;
    }

    .mt-correction {
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .billing__form {
        padding-top: 24px;

        .mt-correction {
            margin: 0;
        }
        .billing-form__row {
            flex-direction: column;
            margin-bottom : 0px;
            gap           : 0;

            .billing-form__item {
                width: 100%;
            }

            .input__container, .antd-dropdown__container {
                width        : 100%;
                margin-bottom: 45px;
            }

            .postalcode {
                .error {
                    margin-bottom: 0;
                }

                .input__text-error {
                    margin-bottom: 24px;
                }
            }

            .tooltip__children, .billing-form__country{
                width: 100%;
            }
        }
    }
}
