@use "../../app/styles/variables" as v;

.license_line {
    line-height: 24px;

    .info__header {
        white-space: nowrap;
        color      : v.$pc-gray-900;
    }

    .info__content {
        color: v.$pc-gray-500;
    }

    .tooltip__children {
        .tooltipe__container {
            width       : 672px;
            height      : 296px;
            white-space : normal;
            padding     : 28px;
            right: -10px;

            .tooltip__ancor {
                border-top: 1px solid v.$pc-gray-200;
                border-left: 1px solid v.$pc-gray-200;
            }
        }

        &:hover {
            .tooltipe__container {
                display  : flex!important;
                flex-wrap: wrap;
            }
        }

        .rights__item {
            width: 300px;
            margin-bottom: 12px;
            min-height   : auto;
            min-width    : auto;
            margin-left  : 0;
            gap          : 16px;

            &:nth-child(2n) {
                margin-right: 12px;
            }

            .title {
                font-size  : 18px;
                 color      : v.$pc-gray-900;
                line-height: 28px;
            }

            .description {
                font-size: 14px;
                line-height: 20px;
            }

            img {
                width: 24px;
                height: 24px;
            }

            .rights__item-content {
                gap: 4px;
            }
        }
    }
}
