@use "../../../app/styles/variables" as v;

.payment-status__container {
    display            : flex;
    flex-direction     : column;
    align-items        : center;
    padding-top        : 96px;
    opacity            : 0;
    animation-fill-mode: forwards;
    animation-delay    : 0.9s;
    animation-name     : showon;
    margin-bottom      : 96px;

    .sub__title {
        font-family: NotoBold;
        line-height: 24px;
        color      : v.$pc-accent-700;
    }

    .title {
        font-family: NotoSemiBold;
        font-size  : 60px;
        line-height: 72px;
        color      : v.$pc-gray-900;
    }

    .payment-status__content-failed {
        max-width    : 744px;
        font-size    : 20px;
        line-height  : 30px;
        margin-bottom: 48px;

        ul {
            margin-left: 21px;
        }
        li {
            list-style-type: disc;
        }

        .server {
            text-align: center;
        }
    }

    .payment-status__shortlinks {
        display      : flex;
        margin-bottom: 96px;
        align-items  : center;

        .link {
            display: flex;
            img {
                margin-right: 8px;
            }
        }
    }

    .payment-status__goback {
        margin-right: 16px;
    }

    .payment-status__links {
        display: flex;
        gap    : 32px;

        .payment-status__links-item {
            padding   : 24px;
            background: v.$pc-gray-50;
            max-width : 318px;

            .link {
                margin-bottom: 8px;
            }

            h5 {
                line-height: 24px;
            }
        }
    }
}

@keyframes showon {
    to {
        opacity: 1;
    }
}

@media (max-width: 1065px) {
    .payment-status__container {
        padding-top: 80px;

        .title {
            font-size    : 48px;
            line-height  : 60px;
            margin-bottom: 20px;
        }

        .payment-status__content-failed {
            max-width: 606px;
        }

        .payment-status__shortlinks {
            margin-bottom: 80px;
        }

        .payment-status__links
        .payment-status__links-item {
            padding: 20px;

            .link.xxl {
                font-size  : 16px;
                line-height: 24px;
            }
        }
    }
}

@media (max-width: 678px) {
    .payment-status__container {
        padding-top: 64px;

        .title {
            font-size    : 36px;
            line-height  : 44px;
            margin-bottom: 16px;
            text-align   : center;
        }

        .payment-status__content-failed {
            max-width: 455px;
        }

        .payment-status__shortlinks {
            flex-direction: column-reverse;
            width         : 100%;
            gap           : 12px;
            align-items   : normal !important;
            margin-bottom : 64px;

            .link {
                display: block !important;
                width  : 100%;

                img {
                    vertical-align: text-top;
                }
            }
        }

        .payment-status__links {
            flex-direction: column;
            width         : 100%;
            gap           : 16px;

            .payment-status__links-item {
                max-width: none;
            }
        }
    }
}
