@use "../../../app/styles/variables" as v;

.filters__container {
    display    : flex;
    align-items: center;

    &.empty-filters {
        .button {
            pointer-events: none;
        }
    }

    .button__container-icon-left {
        margin-right: 0;
    }

    .button {
        width          : 40px;
        height         : 40px;
        padding        : 0;
        display        : flex;
        align-items    : center;
        justify-content: center;
    }

    &.visibility {
        .button {
            width          : auto;
            height         : 40px;
            padding        : 10px 16px;;
            display        : flex;
            align-items    : center;
            justify-content: center;
            color          : v.$pc-gray-500;
        }
    }
}

.filters__popover {
    .filter__list {
        width: 149px;

        background: v.$white;
        border: 1px solid v.$pc-gray-100;
        border-radius: 8px;
        box-shadow: 0 4px 6px -2px #10182808, 0 12px 16px -4px #10182814;
        max-height: 450px;
        overflow-y: scroll;
        padding: 4px 0;
        scrollbar-width: none;

        color: #101828;
        font-family: NotoMedium;
        line-height: 20px;

        .filter__item {
            align-items: center;
            cursor: pointer;
            display: flex;
            padding: 10px 14px;
            position: relative;

            &:hover {
                background: v.$pc-gray-50;
            }

            &.active {
                background-color: v.$pc-gray-50;

                &::after {
                    content             : "";
                    position            : relative;
                    right               : 10px;
                    background-image    : url('../../../shared/ui-kit/icons/check-def-accent600-md.svg');
                    background-repeat   : no-repeat;
                    background-position : center;
                    width               : 20px;
                    height              : 20px;
                    right               : 18px;
                    display             : inline-block;
                    position            : absolute;
                    top                 : 50%;
                    transform           : translateY(-50%);
                }
            }
        }
    }
}