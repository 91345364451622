@use "../../app/styles/variables" as v;

.message-file__item {  
    position     : relative;
    margin-right : 16px;

    &:hover {
        cursor: pointer;

        .message-file__close{
            display: flex;
        }
    }

    .message-file__size {
        line-height  : 18px;
        font-size    : 12px;
        color        : v.$white;
        background   : rgba(52, 64, 84, 0.60);
        border-radius: 4px;
        padding      : 2px 4px;
        position     : absolute;
        bottom       : 4px;
        right        : 4px;
    }

    .message-file__icon {
        width        : 100px;
        height       : 100px;
        border       : 1px solid v.$pc-gray-300;
        border-radius: 8px;
        overflow     : hidden;

        .message-file__img {
            width     : 100%;
            height    : 100%;
            object-fit: cover;
        }
    }

    .message-file__close {
        position: absolute;
        width: 36px;
        height: 36px;
        border-radius: 8px;
        border: 1px solid v.$pc-gray-300;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        background: v.$white;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -17px;
        top: -17px;
        display: none;

        &:hover {
            cursor: pointer;
        }

        img {
           width: 20px;
           height: 20px;
        }
    }

    &.loading {
        display        : flex;
        align-items    : center;
        justify-content: center;
        width          : 100px;
        height         : 100px;
        border         : 1px solid #D0D5DD;
        border-radius  : 8px;
    }
}