@use "../../../app/styles/variables" as v;


.info-panel {
    .faq-info__tittle {
        line-height: 30px;
        color: v.$pc-gray-900;
        margin-bottom: 4px;
        margin-top: 24px;
    }

    .faq-info__suptittle {
        line-height: 20px;
    }

    .accordion__container {
        .link-text {
            color: v.$pc-accent-700;
        }
    }
}