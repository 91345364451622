@use "./variables" as v;

html {
  overflow-x: hidden;
}

html {
  height: 100%;

  body {
    font-size : 16px;
    color     : v.$pc-gray-500;
    height    : 100%;

    &.main__content-bg {
      background: v.$pc-gray-50;
    }
  }
}

#root {
  height: 100%;
}

#portal-root {
  z-index : 214748363;
  position: fixed;
  pointer-events: none;

  &.portal-cookie {
    display       : flex;
    flex-direction: column;
    gap           : 8px;
    align-items   : center;
    bottom        : 16px;
    padding       : 0 32px;
    width         : 100%;

    > div {
      display:flex;
      justify-content: center;
      width: 100%;
    }
  }
}

#snackbar-portal {
  position: fixed;
  top     : 80px;
  right   : 22px;
  z-index : 214748364;
}

.main__container,
.helper__container-item {
  max-width    : 1280px;
  margin       : 0 auto;
  width        : 100%;
  padding-right: 32px;
  padding-left : 32px;
}

.main__container--admin {
  width : 100%;
  height: 100%;

  .background-default {
    overflow-y: auto;
    overflow-x: hidden;
  }

  & + .footer {
    display: none;
  }
}

.full__container {
  margin       : 0 auto;
  width        : 100%;
  padding-right: 32px;
  padding-left : 32px;
}

.main__wrapper {
  height: 100%;
  height: 100dvh;
  position: relative;
}

.main__content {
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
  padding-top    : 80px;
  overflow-x     : hidden;
}

.input__container {
  border-radius: 8px;
}

.header {
  height       : 80px;
  display      : flex;
  align-items  : center;
  position     : fixed;
  top          : 0;
  width        : 100%;
  background   : v.$white;
  z-index      : 99;
  border-bottom: 1px solid v.$pc-gray-100;

  &.non-border {
    border-bottom: none;
  }

  .header__form-container {
    margin: 0 32px;
    width : 100%;
  }

  .header__wrapper-scroll {
    display: none;
    opacity: 0;
    padding   : 18px 0 8px;
    transition: 1s;

    .tags__container {
      .slick-track {
        height: 37px;
        overflow: hidden;
      }
    }
  }

  &.header__fixed {
    height: auto;

    .header__wrapper {
      display: none;
    }

    .header__wrapper-scroll {
      display            : block;
      animation-duration : 0.5s;
      animation-fill-mode: forwards;
      animation-name     : showon;
    }
  }
}

.admin {
  flex-direction: row;
  height        : 100%;

  &.default__asside {
    .project__data {
      pointer-events: none;
      cursor        : none;
    }
  }
}

.admin-navigate__tab {
  a.tab-link {
    background-color: transparent;
    font-family  : NotoMedium;
    font-size    : 16px;
    line-height  : 24px;
    color        : v.$pc-gray-500;
    padding      : 0  4px;
    border-radius: 0;

    &:hover, &.active {
        color                : v.$pc-accent-700;
        text-decoration      : underline;
        text-underline-offset: 16px;
        text-decoration-color: v.$pc-accent-700;
        text-decoration-thickness: 2px;
    }

    &.disabled {
        color         : v.$pc-gray-300;
        pointer-events: none;

        img {
            opacity: .5;
        }
    }
  }
}

.badge {
  padding      : 2px 8px;
  background   : v.$pc-accent-700;
  color        : v.$white !important;
  border-radius: 16px;
  margin-left  : 8px;
}

.arrow-right::after {
  content            : "";
  background-image   : url("../../shared/ui-kit/icons/arrow-accent700.svg");
  background-repeat  : no-repeat;
  background-position: center;
  width              : 20px;
  height             : 20px;
  margin-left        : 8px;
  align-self         : center;
}

.return-back {
  padding-left: 33px!important;

  span {
    font-size  : 18px!important;
    line-height: 28px!important;
    font-family: NotoSemiBold!important;
  }

  &::after {
    content  : "";
    background-image   : url("../../shared/ui-kit/icons/chevron-accent700.svg");
    background-repeat  : no-repeat;
    background-position: center;
    width              : 24px;
    height             : 24px;
    left               : -5px;
    display            : inline-block;
    position           : absolute;
    top                : 50%;
    transform          : translateY(-50%);
  }
}

.return-back__text {
  padding-left: 33px;
  position    : relative;

  span {
    font-size  : 18px!important;
    line-height: 28px!important;
    font-family: NotoSemiBold!important;
    color      : v.$pc-accent-700;

    &:hover {
      cursor         : pointer;
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  &::after {
    content  : "";
    background-image   : url("../../shared/ui-kit/icons/chevron-accent700.svg");
    background-repeat  : no-repeat;
    background-position: center;
    width              : 24px;
    height             : 24px;
    left               : -5px;
    display            : inline-block;
    position           : absolute;
    top                : 50%;
    transform          : translateY(-50%);
  }
}

.slick-list {
  overflow: inherit !important;
}

.divider {
  border-bottom: 1px solid v.$pc-gray-100;
}

.custom__dots {
  .slick-dots {
    display        : flex;
    justify-content: center;
    margin         : 0;
    list-style-type: none;

    li {
      margin: 0;
      width : 18px;
    }

    button {
      display: block;
      width  : 10px;
      height : 10px;
      padding: 0;

      border          : none;
      border-radius   : 100%;
      background-color: v.$pc-gray-300;

      text-indent: -9999px;

      &::before {
        width : 0;
        height: 0;
      }
    }

    li.slick-active button {
      background-color: v.$white;
    }
  }
}

.admin__table {
  .admin__table-header {
    border-bottom: 1px solid v.$pc-gray-200;
  }

  .admin__table-body {
    height       : calc(100vh - 221px);
    overflow-y   : scroll;
    overflow-x   : clip;
  }

  .admin__table-row {
    border-bottom: 1px solid v.$pc-gray-200;
    display      : flex;
    align-items  : center;
    cursor       : pointer;

    &:hover {
      background: v.$pc-gray-300!important;
    }

  }
}

.g-recaptcha {
  .grecaptcha-badge {
    bottom : 100px !important;
    z-index: 9;
  }
}

.form__container {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  padding       : 48px 32px;
  width         : 424px;
  background    : v.$white;
  border-radius : 12px;
  z-index       : 9;
  height        : fit-content;

  .form__header {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    width         : 100%;

    img {
      width: 48px;
    }

    .form__header-title {
      margin-top  : 24px;
      font-family : NotoSemiBold;
      font-size   : 30px;
      line-height : 38px;
      color       : v.$pc-gray-800;
      text-align  : center;
    }

    .login__header-subtitle {
      margin-top   : 12px;
      margin-bottom: 32px;
      line-height  : 24px;
      text-align   : center;
    }
  }

  .form__content {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    width         : 100%;
    gap           : 24px;

    .login__form {
      display       : flex;
      flex-direction: column;
      width         : 100%;

      .input__container {
        margin-top: 44px;
      }

      .input__container:first-child {
        margin-top: 24px;
      }

      .form-helper__text {
        margin-top : 6px;
        line-height: 20px;
      }

      input {
        width: 100%;
      }

      button {
        display: block;
      }
    }
  }
}

.form-item__error {
  border-color: v.$pc-error-500!important;
}

.form-item__warning {
  border-color: v.$sc-orange-500!important;
}

.error {
  font-size  : 14px;
  line-height: 20px;
  margin-top : 6px;
  display    : inline-block;
}

.warning {
  font-size  : 14px;
  line-height: 20px;
  margin-top : 6px;
  color: v.$sc-orange-500;
}

.server__error {
  display       : flex;
  flex-direction: column;
  width         : 100%;
}

.dynamic-page__container {
  margin: 64px 0;

  h1,
  h3,
  h4,
  th,
  .anchor-offset {
    color: #101828;
    font-family: NotoSemiBold;
  }

  h3,
  h4,
  .anchor-offset {
    line-height: 30px;
    margin-bottom: 8px;
  }

  h1,
  h3 {
    margin-top: 22px;
  }

  h1 {
    margin-bottom: 32px;
  }

  .anchor-offset {
    padding-top: 5em;
    margin-top: -4em;
    pointer-events: none;
    font-size: 20px;
  }

  p,
  li,
  tr {
    line-height: 24px;
  }

  p,
  ul {
    margin-bottom: 16px;
  }

  .text-link {
    color: v.$pc-accent-700;
    overflow-wrap: anywhere;
    display: inline;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-color: inherit;
    }
  }

  .mb-0 {
    margin-bottom: 0;
  }

  b,
  .bold {
    //font-weight: bold !important;
    font-family: NotoBold;
  }

  .sub-title {
    color: v.$pc-gray-900;
  }

  .italic {
    text-decoration: italic;
  }

  .unl {
    text-decoration: underline;
  }

  .list {
    padding-left: 40px;
    list-style: disc;
    display: block;

    li {
      list-style: disc;
      display: list-item;
    }
  }

  .sub-list {
    padding-left: 40px;
    list-style: circle;
    display: block;

    li {
      list-style: circle;
      display: list-item;
    }
  }

  table {
    width: 100%;
    border: 1px solid v.$pc-gray-200;

    td,
    th {
      padding: 8px;
      border: 1px solid v.$pc-gray-200;
      word-break: break-word;

      &.noborder {
        border: 0;
      }
    }

    .la {
      width: 34%;
    }

    .lb {
      width: 51%;
    }

    .lc {
      width: 15%;
      text-align: center;
    }
    .ld {
      width: 100px;
      text-align: right;
    }
  }
}

.ant-popover {
  .ant-popover-inner {
      padding      : 0;
      box-shadow   : none;
      border-radius: 0;
  }
}

/*************** PROJECTS *****************/
.new-project__preview-img {
  width        : 100%;
  height       : 100%;
  margin-bottom: auto;
  object-fit   : cover;
  border-radius: 4px;
}

/*************** ANIMATION *****************/

@keyframes showon {
  to {
    opacity: 1;
  }
}


/************** ADAPTIVE ******************/
/* 
@media (max-width: 1440px) {}
@media (max-width: 1065px) {}
@media (max-width: 768px) {}
@media (max-width: 606px) {}
@media (max-width: 475px) {} 
@media (max-width: 375px) {} 
*/

@media (max-width: 1065px) {
  div.main__container,
  div.helper__container-item,
  div.full__container {
    padding-right: 24px;
    padding-left: 24px;
  }
}

@media (max-width: 780px) {
  .main__container,
  .helper__container-item,
  .full__container {
    padding-right: 24px;
    padding-left : 24px;
  }
}

@media (max-width: 606px) {
  .header {
    height: 56px;
  }

  .main__content {
    padding-top: 56px;
  }

  div.main__container,
  div.helper__container-item,
  div.full__container {
    padding-right: 16px;
    padding-left : 16px;
  }
}

@media (max-width: 290px) {
  .full__container {
    padding-left: 5px;
    padding-right: 5px;
  }
}
