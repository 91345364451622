$black       : #000;
$white       : #fff;

/******* PRIMARY COLORS*******/

/* GRAY (HEADING) */
$pc-gray-25     : #FCFCFD;
$pc-gray-50     : #F9FAFB;
$pc-gray-100    : #F2F4F7;
$pc-gray-200    : #EAECF0; 
$pc-gray-300    : #D0D5DD; 
$pc-gray-400    : #98A2B3;
$pc-gray-500    : #667085;
$pc-gray-600    : #475467;
$pc-gray-700    : #344054;
$pc-gray-800    : #1D2939;
$pc-gray-900    : #101828;

/* BLUE (ACCENT) */
$pc-accent-25   : #F9FBFF;
$pc-accent-50   : #F5F8FF;
$pc-accent-100  : #E7EEFF;
$pc-accent-200  : #D7E3FF;
$pc-accent-300  : #C3D6FF;
$pc-accent-400  : #9DBAFD;
$pc-accent-500  : #87A8F2;
$pc-accent-600  : #648CE6;
$pc-accent-700  : #426DCC;
$pc-accent-800  : #3658A6;
$pc-accent-900  : #03236C;

/* RED (ERROR) */
$pc-error-25    : #FFFBFA;
$pc-error-50    : #FEF3F2;
$pc-error-100   : #FEE4E2;
$pc-error-200   : #FECDCA;
$pc-error-300   : #FDA29B;
$pc-error-400   : #F97066;
$pc-error-500   : #F04438;
$pc-error-600   : #D92D20;
$pc-error-700   : #B42318;
$pc-error-800   : #912018;
$pc-error-900   : #7A271A;

/* YELLOW (WARNING) */
$pc-warning-25  : #FFFCF5;
$pc-warning-50  : #FFFAEB;
$pc-warning-100 : #FEF0C7;
$pc-warning-200 : #FEDF89;
$pc-warning-300 : #FEC84B;
$pc-warning-400 : #FDB022;
$pc-warning-500 : #F79009;
$pc-warning-600 : #DC6803;
$pc-warning-700 : #B54708;
$pc-warning-800 : #93370D;
$pc-warning-900 : #7A2E0E;

/* GREEN (SUCCESS) */
$pc-success-25  : #F6FEF9;
$pc-success-50  : #ECFDF3;
$pc-success-100 : #D1FADF;
$pc-success-200 : #A6F4C5;
$pc-success-300 : #6CE9A6;
$pc-success-400 : #32D583;
$pc-success-500 : #12B76A;
$pc-success-600 : #039855;
$pc-success-700 : #027A48;
$pc-success-800 : #05603A;
$pc-success-900 : #054F31;

/******* SECONDARY COLORS *******/

/* BLUE GRAY */
$sc-blue-gray-25  : #FCFCFD;
$sc-blue-gray-50  : #F8F9FC;
$sc-blue-gray-100 : #EAECF5;
$sc-blue-gray-200 : #D5D9EB;
$sc-blue-gray-300 : #AFB5D9;
$sc-blue-gray-400 : #717BBC;
$sc-blue-gray-500 : #4E5BA6;
$sc-blue-gray-600 : #3E4784;
$sc-blue-gray-700 : #363F72;
$sc-blue-gray-800 : #293056;
$sc-blue-gray-900 : #101323;

/* BLUE LIGHT */
$sc-blue-light-25 : #F5FBFF;
$sc-blue-light-50 : #F0F9FF;
$sc-blue-light-100: #E0F2FE;
$sc-blue-light-200: #B9E6FE;
$sc-blue-light-300: #7CD4FD;
$sc-blue-light-400: #36BFFA;
$sc-blue-light-500: #0BA5EC;
$sc-blue-light-600: #0086C9;
$sc-blue-light-700: #026AA2;
$sc-blue-light-800: #065986;
$sc-blue-light-900: #0B4A6F;

/* PURPLE */
$sc-purple-25     : #FAFAFF;
$sc-purple-50     : #F4F3FF;
$sc-purple-100    : #EBE9FE;
$sc-purple-200    : #D9D6FE;
$sc-purple-300    : #BDB4FE;
$sc-purple-400    : #9B8AFB;
$sc-purple-500    : #7A5AF8;
$sc-purple-600    : #6938EF;
$sc-purple-700    : #5925DC;
$sc-purple-800    : #4A1FB8;
$sc-purple-900    : #3E1C96;

/* VIOLET */
$sc-violet-25     : #FCFAFF;
$sc-violet-50     : #F9F5FF;
$sc-violet-100    : #F4EBFF;
$sc-violet-200    : #E9D7FE;
$sc-violet-300    : #E1C0FE;
$sc-violet-400    : #CF98FD;
$sc-violet-500    : #C180F9;
$sc-violet-600    : #AA5AEE;
$sc-violet-700    : #7F36BD;
$sc-violet-800    : #6F34A1;
$sc-violet-900    : #5A2E7F;

/* PINK */
$sc-pink-25       : #FFFBFE;
$sc-pink-50       : #FFF4FD;
$sc-pink-100      : #FFE9FB;
$sc-pink-200      : #FFD7F9;
$sc-pink-300      : #FFAEF2;
$sc-pink-400      : #FC7AE8;
$sc-pink-500      : #F24FD9;
$sc-pink-600      : #DD25C0;
$sc-pink-700      : #C815AC;
$sc-pink-800      : #A4188E;
$sc-pink-900      : #8F187C;

/* ROSE */
$sc-rose-25       : #FEF6FB;
$sc-rose-50       : #FFF6FA;
$sc-rose-100      : #FFE2EE;
$sc-rose-200      : #FFD2E5;
$sc-rose-300      : #FEA9CC;
$sc-rose-400      : #F97CB0;
$sc-rose-500      : #FA64A2;
$sc-rose-600      : #E64587;
$sc-rose-700      : #D21865;
$sc-rose-800      : #A5104E;
$sc-rose-900      : #7D2248;

/* ORANGE */
$sc-orange-25     : #FFFAF5;
$sc-orange-50     : #FFF6ED;
$sc-orange-100    : #FFEAD5;
$sc-orange-200    : #FDDCAB;
$sc-orange-300    : #FEB273;
$sc-orange-400    : #FD853A;
$sc-orange-500    : #FB6514;
$sc-orange-600    : #EC4A0A;
$sc-orange-700    : #C4320A;
$sc-orange-800    : #9C2A10;
$sc-orange-900    : #7E2410;
