@use "../../app/styles/variables" as v;

.header__panel {
    .notauth {
        opacity: 0;
        animation-fill-mode: forwards;
        animation-delay: 0.3s;
        animation-name: showon;
    }

    .header__wrapper-badge {
        &.badge__container {
            background : v.$pc-success-500;
            margin-left: 4px;
            position   : relative;
            bottom     : 4px;

            .badge__text {
                color: v.$white;
            }
        }
    }

    .avatar__wrapper {
        margin-left: 2px;
        cursor: pointer;
    }

    .link, .button {
        white-space: nowrap;
        display: flex;
        text-decoration: none !important;

        &.tertiary-gray {
            padding: 10px 18px;
        }
    }

    .header__link-notification,
    .header__link-cart {
        padding: 0;
        margin-right: 30px;
    }
}

@keyframes showon {
    to {
        opacity: 1;
    }
}

@media (max-width: 606px) {
    .header__panel {
        .header__link-cart,
        .header__link-notification {
            margin-right: 20px;
        }
    }
}

@media (max-width: 330px) {
    .header__panel {
        .header__wrapper-badge {
            &.badge__container {
                margin-right: 9px;
            }
        }

        & > div {
            margin-left: 0;
        }
    }
}
