@use "../../../../app/styles/variables" as v;

.unsaved-changes-popup__wrapper {
    display      : flex;
    border-radius: 12px;
    background   : v.$white;
    padding      : 24px;
    margin       : 16px;
    z-index      : 9;
    gap          : 24px;

    .unsaved-changes-popup__title {
        line-height  : 28px;
        font-family  : NotoSemiBold;
        color        : v.$pc-gray-900;
        margin-bottom: 8px;
    }

    .unsaved-changes-popup__subtitle {
        line-height: 20px;
        color      : v.$pc-gray-500;
    }

    .unsaved-changes-popu__actions {
        display: flex;
        margin-top: 32px;
        gap: 12px;

        button {
            width          : 206px;
            display        : flex;
            justify-content: center;
        }
    }
}

@media (max-width: 1474px) {
    .unsaved-changes-popup__wrapper {
        flex-direction: column;
        align-items   : center;
    }
}

@media (max-width: 1328px) {
    .unsaved-changes-popup__wrapper {
        .unsaved-changes-popu__actions {
            flex-direction: column;
            align-items   : center;

            .button {
                width: 100%;
            }
        }
    }
}