@use "../../../app/styles/variables" as v;
.recovery__form-container {
    padding: 96px 32px 48px 32px;

    .login__header-subtitle {
        &.bold {
            margin-top : 0;
            font-family: NotoSemiBold;
        }
    }

    .form__header-logo-container {
        height: 72px;
        width : 72px;

        .form__header-icon {
            position        : relative;
            right           : 3px;
            top             : -3px;

            display         : flex;
            width           : 78px;
            height          : 78px;
            padding         : 18px;
            justify-content : center;
            align-items     : center;
            border-radius   : 50%;
            border          : 12px solid v.$pc-accent-50;
            background      : v.$pc-accent-100;
        }
    }

    .form__content-line {
        display    : flex;
        align-items: center;
        font-size: 14px;
    }

    .link-gray {
        img {
            opacity: .7;
        }

        &:hover {
            img {
                opacity: .9;
            }
        }

    }
}
