@use "../../../app/styles/variables" as v;

.donate__container {
    margin-bottom: 24px;

    .donate-switcher__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 56px;

        :nth-child(3) {
            left: 0;
        }
        :nth-child(4) {
            left: 20%;
        }
        :nth-child(5) {
            left: 40%;
        }
        :nth-child(6) {
            right: 0;
        }

        .switcher-step {
            position: absolute;

            .donat-toggle {
                width: 24px;
                min-width: 24px;
                height: 24px;
                background-color: v.$white;
                border: 1px solid v.$pc-accent-600;
                border-radius: 50%;
                cursor: pointer;
                filter: drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06)) drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.10));

                &.passed {
                    border: 1px solid v.$white;
                    background-color: v.$pc-accent-600;
                }
            }

            .donat-label {
                position: absolute;
                bottom: -27px;
                left: 0;
                font-family: NotoMedium;
                font-size: 16px;
                line-height: 24px;
                color: v.$pc-gray-900;

                &.first {
                    left: 3px;
                }

                &.last {
                    right: 0;
                    left: auto;
                }
            }
        }

        .toggle-line {
            height: 8px;
            border-radius: 4px;
            
            &.background {
                width: calc(100% - 24px);
                background: v.$pc-gray-200;
            }
        }

        .switcher-line {
            position: absolute;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 12px;

            .toggle-line {
                width: 0;
                background: v.$pc-accent-600;
            }
        }
    }

    .donate__description {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        cursor: pointer;

        h5 {
            line-height: 24px;
            max-width: calc(100% - 36px);

            span {
                display: inline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .arrow__icon {
            transform  : rotate(270deg);
            width      : 20px;
            height     : 20px;

            &.down {
                transform  : rotate(90deg);
            }
        } 
    }

    .donate__input-container {
        margin-top: 8px;
    }
}
