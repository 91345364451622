@use "../../../app/styles/variables" as v;

.profile__password {
        display         : flex;
        background-color: v.$pc-gray-50;
        flex-direction  : column;
        align-items     : center;
        margin-top      : 51px;
        padding         : 48px 24px 96px;


    .profile__password-header {
        margin-bottom: 44px;

        .title {
            font-family  : NotoMedium;
            font-size    : 30px;
            line-height  : 38px;
            color        : v.$pc-gray-900;
            margin-bottom: 4px;
        }

        .sub-title {
            line-height: 24px;
        }
    }

    .profile__password-container {
        display        : flex;
        width          : 530px;
        padding        : 58px 32px 32px;
        justify-content: center;
        border-radius  : 12px;
        border         : 1px solid v.$pc-gray-200;
        background     : v.$white;
        box-shadow     : 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .profile__password-form {
            width: 100%;
        }

        .password-forgot {
            margin-bottom  : 50px;
            display        : flex;
            justify-content: flex-end;
            align-items    : center;
            margin-top     : 24px;
        }

        .profile__buttons-block {
            display        : flex;
            justify-content: flex-end;
            min-height     : 40px;
            gap            : 12px;
            padding-top    : 16px;
            margin-top     : 24px;
            border-top     : 1px solid v.$pc-gray-100;
        }
    }
}

@media (max-width: 974px) {
    .profile__password {
        padding: 36px 24px 80px;
    }
}

@media (max-width: 606px) {
    .profile__password {
        padding: 32px 24px 64px;
        margin-top: 52px;

        .profile__password-header {
            margin-bottom: 32px;

            .title {
                font-size  : 24px;
                line-height: 32px;
            }
        }
        .profile__password-container {
            padding: 32px 16px;
            width: 100%;
        }
    }
}
