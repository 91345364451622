@use "../../app/styles/variables" as v;

.limit-popup__bkg {
    overflow: auto;
    z-index: 99;
}

.limit-popup__wrapper {
    padding      : 24px;
    border-radius: 12px;
    box-shadow   : 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    width        : 400px;
    background   : v.$white;
    z-index      : 9;
    margin       : 16px;

    .limit-popup__imgs {
        position: relative;

        .limit__icon-feature {
            position: absolute;
            bottom  : 0;
            left    : 0;
        }

        .limit__img {
            margin-left: 16px;
            position   : relative;
            width      : 116px;
        }
    }

    .limit__title {
        line-height: 28px;
        font-family: NotoSemiBold;
        color      : v.$pc-gray-900;
        margin-top : 16px;
    }

    .limit__suptitle {
        line-height: 20px;
        margin-top : 8px;
        color      : v.$pc-gray-500;
    }

    .limit__actions {
        margin-top     : 32px;
        display        : flex;
        justify-content: flex-end;
    }
}

@media (max-height: 440px) {
    .limit-popup__bkg {
        align-items: baseline;
    }
}