@use "../../../app/styles/variables" as v;

.user-panel__wrapper {
    height: calc(100dvh - 80px);
}

.mobile-user-panel__wrapper {
    display: flex;
    width  : 100%;
    height : calc(100dvh - 80px);

    .admin__aside {
        max-width   : none;
        width       : 100%;
        border-right: none;
        overflow    : hidden;
        position    : unset;

        .admin__aside-content {
            height: 100%;
            padding-bottom: 137px;

            .project__data {
                margin-bottom: 0;
            }
        }

        .fixed {
            position: sticky;
        }
    }

    .admin__content {
        display: none;
    }

    &.chat-open {
        height: 100dvh;

        .admin__aside {
            display: none;
        }
    
        .admin__content {
            display: flex;
        }
    }

    .background-default {
        top: 41px;

        &.delete-modal {
            padding: 16px;
            top    : 0;
        }
    }
}

@media (max-width: 670px) {
    .admin {
        &.default__asside {
            height: calc(100dvh - 80px);
        }
    }

    .user-panel__wrapper {
        height: auto;
    }
}

@media (max-width: 606px) {
    .admin {
        &.default__asside {
            height: calc(100dvh - 56px);
        }
    }
    
    .mobile-user-panel__wrapper {
        height : calc(100dvh - 56px);
    }
}