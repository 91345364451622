@use "../../../app/styles/variables" as v;

.radio__container {
  cursor: pointer;

  &:first-child {
    margin-bottom: 12px;
  }

  .radio {
    width        : 20px;
    height       : 20px;
    border-radius: 50%;
    border       : 1px solid v.$pc-gray-300;

    .radio__mark {
      width        : 8px;
      height       : 8px;
      border-radius: 50%;
    }
  }

  &.disabled {
    cursor        : not-allowed;
    pointer-events: none;

    .radio {
      border    : 1px solid v.$pc-gray-300;
      background: v.$pc-gray-100;

      .radio__mark {
        display: none;
      }
    }
  }

  &.active {
    cursor        : not-allowed;
    pointer-events: none;
    .radio {
      background-color: v.$pc-accent-50;
      border          : 1px solid v.$pc-accent-600;
      .radio__mark {
        background: v.$pc-accent-600;
      }
    }
  }
}
