@use "../../../app/styles/variables" as v;

.login__form-container, .recovery__form-container, 
.reset__form, .security-form {
    margin: 16px;
}

.login__form-container {

    .login__memory {
        display        : flex;
        flex-direction : row;
        justify-content: space-between;
    }

    .login__footer {
        display    : flex;
        align-items: center;
        margin-top : 32px;

        span {
            font-size: 14px;
        }
        
        .button {
            font-family: NotoRegular;
        }
    }

    .icon__right-icon {
        cursor: pointer;
    }
}

@media (max-width: 1000px) {
    .user-info__wrapper {
        &.login {
            display: none;
        }
    }
}

@media (max-width: 768px) {
    .admin__content {
        position: static !important;
    }

    .admin__aside {
        &.login {
            display: none;
        }
    }

    .login__form-container, .recovery__form-container, 
    .reset__form, .security-form {
        margin-top: 96px;
    }
    
}
