@use "../../../app/styles/variables" as v;

/********************** RATES ************************/
.options__container {
  padding: 96px 0;

  .slick-slider {
    margin-bottom: 0;
  }

  .options__header {
    text-align: center;

    h1 {
      color: v.$pc-gray-900;
      font-family: NotoBold;
      font-size: 36px;
      margin-bottom: 64px;
      line-height: 44px;
    }
  }

  .options__items {
    width: 100%;
    display: flex;
    justify-content: center;

    .carousel {
      &.default {
        .slick-track {
          transform: translate3d(0, 0, 0) !important;
        }
      }
    }
    .slick-list {
      overflow: hidden !important;
      width: 1280px !important;
    }

    .options__item {
      width: 382px;
      padding: 24px;
      border: 1px solid v.$pc-gray-200;
      background: v.$white;
      margin: 0 22px 22px;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);

      .title {
        font-family: NotoBold;
        font-size: 20px;
        line-height: 30px;
        color: v.$pc-gray-900;
      }

      .subtitle {
        font-family: NotoBold;
        font-size: 16px;
        line-height: 24px;
        color: v.$pc-gray-700;
      }

      .text {
        line-height: 24px;
        color: v.$pc-gray-500;
      }

      .switch {
        .switch-text {
          font-family: NotoBold;
          color: v.$pc-gray-500;

          &.active {
            color: v.$pc-accent-600;
          }
        }

        .switcher__container {
            &.check {
                background: #648CE6;
            }
        }
      }

      .how-works__ico {
        width: 20px;
        height: 20px;
        margin-left: 8px;
        transform: rotate(90deg);
      }

      .options__get,
      .options__how-works {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        margin-bottom: 16px;

        .get-item {
          display: flex;
          gap: 6px;
        }

        img {
          margin: 6px;
          width: 16px;
          height: 16px;
        }
      }

      .options__how-works {
        padding-left: 20px;
        list-style: decimal;
        margin-bottom: 32px;

        li {
          list-style: decimal;
        }
      }

      a {
        display: block;
      }
    }
  }

  .ant-carousel .slick-prev::before,
  .ant-carousel .slick-next::before {
    color: transparent !important;
  }

  .ant-carousel {
    .slick-prev {
      display: flex;
      background-color: v.$white;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border: 1px solid v.$pc-gray-200;
      left: -8% !important;

      &::after {
        color: v.$pc-gray-500;
        width: 16px;
        height: 16px;
        top: 0;
        bottom: 0;
        left: 6px;
        right: 0px;
        margin: auto;
        opacity: 1;
      }
    }

    .slick-next {
      display: flex;
      background-color: v.$white;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border: 1px solid v.$pc-gray-200;
      right: -8% !important;

      &::after {
        color: v.$pc-gray-500;
        width: 16px;
        height: 16px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 6px;
        margin: auto;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 1375px) {
  .options__container {  
    .options__items {
      .slick-list {
        width: 860px !important;
      }
    }
  }
}

@media (max-width: 1065px) {
  .options__container {
    .ant-carousel {
      .slick-prev {
        left: 0 !important;
      }
      .slick-next {
        right: 0 !important;
      }
    }
  }
}

@media (max-width: 880px) {
  .options__container {  
    .options__items {
      .slick-list {
        width: 428px !important;

        .slick-slide {
          width: 428px !important;
        }
      }

      .ant-carousel {
        .slick-prev {
          left: -8% !important;
        }
        .slick-next {
          right: -8% !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
    .options__container {
        padding: 80px 24px;

        .options__header {
            h1 {
              margin-bottom: 48px;
            }
        }
    }
}

@media (max-width: 606px) {
  .options__container {
    .options__header {
        h1 {
          font-size: 30px;
          line-height: 38px;
        }
    }
  }
}

@media (max-width: 530px) {
  .options__container {
    .options__items {
      .options__item {
        width: 300px !important;
      }

      .slick-list {
        width: 345px !important;

        .slick-slide {
          width: 345px !important;
        }
      }

      .ant-carousel {
        .slick-prev {
          left: 0 !important;
        }
        .slick-next {
          right: 0 !important;
        }
      }
    }
  }
}
