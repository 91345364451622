@use "../../../app/styles/variables" as v;

.info-panel {
    .brief-info__tittle {
        line-height  : 30px;
        color        : v.$pc-gray-900;
        margin-bottom: 4px;
        margin-top   : 24px;
    }

    .brief-info__suptittle {
        line-height: 20px;
    }
}
