@use "../../../app/styles/variables" as v;

.project__info {
    padding-bottom: 16px;
    
    &.project__info-error {
        .user-project__img-wrapper{
            border: 1px solid v.$pc-error-500;
        }
    }

    textarea {
        border-radius: 10px;
        margin-left  : 12px;
        border       : none;
        outline      : 1px solid v.$pc-gray-300!important;
        padding      : 10px 14px;
        resize       : none;
        height       : 66px;
        width        : auto;
        line-height  : 24px;
        font-size    : 16px;
        color        : v.$pc-gray-900;
    }
}

.admin-info__wrapper {
    height    : calc(100dvh - 141px);
    overflow-y: scroll;
    overflow-x: hidden;
    position  : relative;

    .info__title {
        color        : v.$pc-gray-700;
        margin-top   : 16px;
        margin-bottom: 4px;
        line-height  : 20px;
        padding      : 10px 16px 12px 0;
        font-family  : NotoMedium;    
    }

    .admin-notification {
        margin-bottom: 16px;
        
        .button {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }

    .info__customer {
        display: none;
        padding-bottom: 16px;

        .info__customer-container {
            padding    : 12px 16px;
            align-items: center;
            align-self : stretch;
            border-radius: 8px;
            background : v.$pc-gray-50;

            .customer__name {
                color      : v.$pc-gray-900;
                font-size  : 16px;
                line-height: 24px;
            }

            .customer__email {
                color      : v.$pc-gray-500;
                line-height: 20px;
            }
        }
    }
    
    .info__license, 
    .info__team {
        padding-bottom: 16px;

        .radio__container:first-child {
            margin-bottom: 8px;
        }

        .mr-12 {
            margin-right: 8px;
        }

        .radio__content {
            color: v.$pc-gray-700;
        }
    }

    .info__steps {
        padding-bottom: 16px;
    }

    .info__team {
        .antd-dropdown__container {
            width: 100%;

            .avatar__wrapper img {
                width : 100% !important;
                height: 100% !important;
            }
        }
        
        .render-executors {
            margin-top   : 12px;
            margin-bottom: 4px;
            padding      : 0 16px;

            .avatar-label__container {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .author__name {
                color: v.$pc-gray-700;
            }

            .author__text {
                color      : v.$pc-gray-500;
                font-family: NotoRegular;
            }
        }   

        .executor__wrapper {
            cursor    : pointer;
            background: v.$white;
            &:hover {
                background: v.$pc-gray-50;
            }

            .avatar__wrapper {
                margin-right: 8px;
            }

            .author__name {
                color        : v.$pc-gray-900;
                font-size    : 16px;
                line-height  : 24px;
                font-family  : NotoMedium;
                overflow-x   : hidden;
                padding      : 0;
            }
        }
    }

    .info__archive_actions {
        display        : flex;
        justify-content: space-between;
        flex-direction : column;
        margin-top     : 16px;
        margin-bottom  : 32px;
        gap            : 24px;

        .button {
            width          : 100%;
            justify-content: center;
        }
    }

    .info__actions {
        position       : sticky;
        display        : flex;
        justify-content: space-between;
        bottom         : -7px;
        background     : v.$white;
        padding        : 16px 0px;
        z-index        : 99;
        gap            : 24px;

        .button {
            width          : 100%;
            justify-content: center;
            height: 44px;

            &.mr-0 {
                margin-right: 0;
            }
        }
    }

    .content_milestone {
        display: flex;

        &:first-child {
            margin-top: 4px;
        }

        .button {
            :hover {
                text-decoration: underline;
            }
        }
    }

    .time_milestone__title {
        line-height  : 20px;
        font-family  : NotoSemiBold;
        color        : v.$pc-gray-700;
        font-size    : 14px;

        span {
            line-height  : 20px;
            font-family  : NotoSemiBold;
            color        : v.$pc-gray-700;
            font-size    : 14px;
        }

        .time_milestone__highlight {
            color: v.$pc-accent-700;
        }
    }

    .hours_milestone{
        color        : v.$pc-gray-900;
        font-size    : 14px;
        line-height  : 20px;
    }

    .time_milestone {
        font-size    : 14px;
        line-height  : 20px;
        margin-left  : 5px;
    }

    .cost_milestone {
        font-size    : 14px;
        line-height  : 20px;
        font-family  : NotoSemiBold;
        color        : v.$pc-accent-700;
    }

    .project-archive {
        position        : absolute;
        background-color: v.$white;
        opacity         : 0.7;
        z-index         : 99;
        left            : 0;
        right           : 0;
        bottom          : 0;
        top             : 0;

        & ~ .info__actions {
            .button {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 1027px) {
    .admin-info__wrapper {
        .info__customer {
            display: block;
        }
    }
}
