@use "../../../app/styles/variables" as v;


.panel__user-info {
    border-left: 1px solid v.$pc-gray-200;
}

.admin-user-info__wrapper {
    width       : 691px;
    height          : calc(100vh - 150px);
    overflow-y      : scroll;
    position        : relative;
    background-color: v.$pc-gray-50;
    padding-bottom  : 70px;

    .admin-user-info__settings-form {
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
        padding       : 52px 32px 0;
    }

    .input__container, .antd-dropdown__container {
        width : 100%;
        height: 46px;

        .input {
            background-color: v.$white;
        }
    }

    .admin-user-info__input, 
    .admin-user-info__inputs-block {
        width         : 100%;
        margin-bottom : 20px;
        padding-bottom: 20px;
    }

    .admin-user-info__inputs-block {
        display: flex;
        gap    : 24px;
    }

    .admin-user-info__avatar-block {
        padding-bottom: 20px;
        width: 100%;

        label {
            margin-bottom: 6px !important;
            color: #344054;
            font-family: NotoMedium;
            line-height: 20px;
            font-size: 14px;
        }

        .avatar-uploader {

            .avatar__wrapper {
                margin-right: 20px;
                min-width   : 64px;
            }

            .uploader__wrapper {
                width: 100%;
            }
        }
    }

    .admin-user-info__address-container {
        width         : 100%;
        padding-bottom: 20px;

        .profile__inputs-drop .profile__input {
            width: 50%;
        }
    }

    .profile__inputs-drop {
        display      : flex;
        width        : 100%;
        margin-bottom: 20px;
        gap          : 24px;
    }

    .admin-user-info__role-container {
        width: 100%;
    }

    .profile__notifications {
        line-height: 20px;
        align-items: flex-start;

        .content-title {
            font-family: NotoMedium;
            color      : v.$pc-gray-700;
        }
    }

    .mb-43 {
        margin-bottom: 43px;
    }

    .admin-user-info__delete {
        margin: 20px 32px 32px;
    }
}

.admin-user-info__buttons-block {
    position       : absolute;
    bottom         : 0px;
    background     : v.$white;
    padding        : 16px 32px;
    z-index        : 98;
    width          : 100%;
    display        : flex;
    justify-content: flex-end;

    .button {
        height: 44px;

        &:first-child {
            margin-right: 12px;
        }

        &.mr-0 {
            margin-right: 0;
        }
    }

    .secondary-gray {
        padding: 10px 15px;
    }
}

@media (max-width: 1750px) {
    .admin-user-info__wrapper {
        width: 590px;
    }
}

@media (max-width: 1650px) {
    .admin-user-info__wrapper {
        min-width: 375px;
        max-width: 375px;

        .admin-user-info__avatar-block {
            .avatar-uploader {
                display: block;

                .avatar__wrapper {
                    margin-right : 20px;
                    margin-bottom: 20px;
                    min-width    : 64px;
                }
            }
        }
    }
}
