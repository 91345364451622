@use "../../app/styles/variables" as v;

.keywords__container {
    .keywords__title {
        font-size  : 16px;
        line-height: 24px;
        color      : v.$pc-gray-900;
        font-family: NotoMedium;
    }

    .keywords__label {
        font-family: NotoSemiBold;
        line-height: 20px;
        font-size  : 14px;
        color      : v.$pc-gray-700;
    }

    .keywords__list {
        display  : flex;
        flex-wrap: wrap;
    }

    .tag__parent {
        text-transform: inherit!important;
        
        img {
            width : 8px;
            height: 16px;
            &.button__img-left {
                transform: rotate(180deg);
            }
        }

        .button__container-icon-left {
            display: none;
        }

        &.right {
            .button__container-icon-left{
                display: flex;
            }

            .button__container-icon-right {
                display: none;
            }
        }
    }

    .keywords__button {
        position: relative;

        .keywords__item {
            color         : v.$pc-gray-500;
            font-family   : NotoSemiBold;
            text-transform: capitalize;
            padding       : 7px 34px 7px 14px;
            cursor        :default;
        }

        .delete-keyword {
            position: absolute;
            top     : 8px;
            right   : 12px;
            padding : 0;

            .button__container-icon {
                margin   : 0;
            }
        }
    }
}
