@use "../../../../app/styles/variables" as v;

.item-actions {
    display    : flex;
    align-items: center;
    cursor     : pointer;

    .close, .open {
        display        : flex;
        font-family    : NotoBold;
        font-size      : 14px;
        line-height    : 20px;
        color          : v.$pc-accent-600;
        width          : 20px;
        height         : 36px;
        justify-content: center;
        align-items    : center;
    }

    .open {
        background-color: v.$pc-gray-50;
        border          : 2px solid v.$pc-accent-100;
        border-radius   : 4px;
    }
}

.menu-actions {
    border-radius: 8px;
    border: 1px solid v.$pc-gray-100;
    overflow: hidden;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    width: auto;
    min-width: 160px;

    .menu__item {
        border-bottom: 1px solid v.$pc-gray-100;

        &:hover {
            background: v.$pc-gray-50;
        }
    }

    .menu__item:last-child {
        border-bottom: 0;
    }

    .link {
        padding: 0;
        display: block;
        
        .pd {
            cursor : pointer;
            padding     : 10px 16px;
            display     : flex;
            align-items : center;
            text-align  : left;
            text-decoration: none;
            color       : v.$pc-gray-700;
        }

        h6 {
            width      : 100%;
            font-family: NotoMedium;
            color      : v.$pc-gray-500;
            line-height: 20px;
            pointer-events: none;
        }

        img {
            height   : 16px;
            min-width: fit-content;
            pointer-events: none;
        }
    }
}

@media (max-width: 606px) {
    .item-actions {
        margin-right: 20px;
    }
}
