@use "../../app/styles/variables" as v;

.similar__container {
    .similar__slider {
        height: 150px;

        .slick-slider {
            margin-bottom: 0;
        }

        .similar__img {
            height   : 150px;
            min-width: 70px;
        }

        .carousel-arrow {
            display: flex !important;
            position : absolute;
            align-items: center;
            justify-content: center;

            &::before {
                content: "";
            }
        }

        .slick-list {
            overflow: hidden!important;
        }
    }
}

.similar__title {
    line-height  : 24px;
    font-family  : NotoMedium;
    color        : v.$pc-gray-900;
    margin-top   : 32px;
    margin-bottom: 0;
}

@media (max-width: 1065px) {
    .similar__container {

        .similar__slider {
            .carousel-arrow{
                &.prev {
                    left: -20px;
                }
                &.next {
                    right: -20px;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .similar__container {

        .similar__slider {
            .carousel-arrow{
                width: 32px;
                height: 32px;
                &.prev {
                    left: -13px;
                }
                &.next {
                    right: -13px;
                }
            }
        }
    }
}
