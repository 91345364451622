@use "../../../app/styles/variables" as v;

.modal__wrapper {
    position: relative;

    .modal__background {
        display        : none;
        justify-content: center;
        align-items    : center;
        width          : 100%;
        background     : rgba(52, 64, 84, 0.30);
        backdrop-filter: blur(4px);
        position       : fixed;
        top            : 0;
        bottom         : 0;
        left           : 0;
        z-index        : 99;
        padding        : 16px;
        overflow       : hidden;
        -webkit-overflow-scrolling: touch;
        cursor         : default;

        &.open {
            display: flex;
        }
    }

    .modal__container {
        padding      : 12px 16px;
        border-radius: 8px;
        box-shadow   : 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
        width        : 100%;
        background   : v.$white;
    }

    .modal__close {
        display: flex;
        justify-content: flex-end;

        img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .modal__title {
        line-height: 20px;
        font-family: NotoSemiBold;
        color      : v.$pc-gray-900;
    }

    .modal__subtitle {
        line-height: 20px;
        margin-top : 4px;
        color      : v.$pc-gray-500;
    }
}
