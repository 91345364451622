@use "../../app/styles/variables" as v;

.product__preview-container {
    flex-direction: row;
    padding       : 32px;
}

.close__icon {
    position: absolute;
    top     : -35px;
    right   : 10px;
    cursor  : pointer;
}

.product__info-container {
    flex-direction: column;
    align-items   : flex-start;
    margin-right  : 64px;
    width         : 804px;

    .product__info-link {
        margin: 0 auto;
    }

    .info__avatar {
        width       : auto;
        margin-right: 0;
        align-self  : center;
    }
}

.info__description, .info__description-tablet {
    max-width: 348px;

    .info__title {
        line-height: 30px;
        color      : v.$pc-gray-900;
        font-family: NotoSemiBold;
    }

    .info__descr {
        line-height: 24px;
        color      : v.$pc-gray-500;

        &.show {
            overflow: auto;
            height  : auto;
        }
    }

    .info__btn {
        opacity            : 0;
        animation-fill-mode: forwards;
        animation-delay    : 0.5s;
        animation-name     : showon;
    }

    .cart__btn, .free__btn {
        width          : 100%;
        justify-content: center;
    }

    .cart-success-added {
        display: none;

        .cart__ico {
            width        : 44px;
            height       : 44px;
            padding      : 12px;
            background   : v.$pc-accent-600;
            box-shadow   : 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            margin-bottom: 4px;
            cursor       : pointer;

            &.secondary {
                background: v.$pc-accent-50;
                width     : auto;
                padding   : 10px 18px;
                height    : 44px;

                h6 {
                    line-height: 20px;
                    margin-left: 8px;
                    color      : v.$pc-accent-700;
                    font-family: NotoSemiBold;
                }
            }
        }

        h5 {
            line-height: 24px;
        }
    }

    .product-in-cart__wrapper {
        .cart-success-added {
            display: flex;
        }

        .cart__btn {
            display: none;
        }
    }
}

.info__description-tablet {
    display  : none;
    max-width: none;
}

.additional-data,
.additional-data-mobile {
    flex-direction: column;
    align-items   : flex-start;
    width         : 100%;

    .avatar-label__container {
        margin-bottom: 32px;

        .author__name,
        .author__email {
            color: v.$pc-gray-700;
        }
    }
}

.additional-data-mobile {
    display: none;
}

.editillustration__wrapper {
    .tooltipe__container {
        right: -10px !important;
    }

    .tooltip__children {
        display: flex;
    }

    .modal__wrapper {
        display: none;

        .modal__container {
            max-width: 574px;
        }
    }

    .tooltipe__title {
        font-family  : NotoSemiBold;
        line-height  : 20px;
        margin-bottom: 4px;
        color        : v.$pc-gray-700;
    }

    .tooltipe__subtitle {
        line-height: 20px;
        color      : v.$pc-gray-500;
    }

    .edit-link {
        color      : v.$pc-accent-700;
        line-height: 20px;
        font-family: NotoSemiBold;
        cursor     : pointer;
        
        &:hover {
            text-decoration      : underline;
            text-underline-offset: 4px;
            text-decoration-color: inherit;
        }
    }
}

.license_line {
    .modal__wrapper {
        display: none;
    }

    .modal__container {
        padding  : 12px;
        max-width: 684px;

        .modal__content {
            display  : flex;
            flex-wrap: wrap;
            padding  : 16px;
            gap      : 12px;

            .rights__item {
                max-width    : 300px;
                margin-bottom: 0;
            }

            .rights__item-content {
                gap: 4px
            }

            .title {
                color      : v.$pc-gray-900;
                font-size  : 18px;
                line-height: 28px;
            }

            .description {
                font-size  : 14px;
                line-height: 20px;
            }
        }
    }
}

.price_line {
    .modal__wrapper {
        display: none;
    }
    .modal__container {
        max-width: 574px;
    }
}

.similar__not-found {
    display   : flex;
    padding   : 24px;
    margin-top: 24px;
    background: v.$pc-gray-50;
    width : 804px;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;

    .similar__not-found-content {
        max-width: 468px;
    }

    .title {
        font-family: NotoBold;
        margin-bottom: 8px;
    }

    .link {
        height: 24px;
        min-width: 172px;
        margin-left: 12px;
    }

    h5 {
        line-height: 24px;
    }
}

@keyframes showon {
   to {
        opacity: 1;
    }
}

@media (max-width: 1300px) {
    .product__info-container {
        width: 50vw;
    }

    .similar__not-found {
        width : 56vw;
    }
}

@media (max-width: 1065px) {
    .product__container-info {
        flex-direction: column;
        width         : 90vw;
        margin        : 0 auto;

        .product__info-container {
            width : 100%;
            margin: 0;
        }

        .additional-data {
            display: none;
        }

        .additional-data-mobile {
            display   : block;
            margin-top: 16px;

            &.keywords {
                display: flex;
            }

            .avatar-label__container {
                margin-bottom: 20px;
            }
        }

        .info__description-tablet {
            display: flex;
            gap: 24px;

            .description-left {
                flex: 1 0 0;
            }
        }

        .info__description {
            display: none;
            max-width: 100%;

            .cart__btn{
                width: auto;
            }
        }
    }

    .editillustration__wrapper,
    .license_line, .price_line {
        .tooltip__children {
            display: none;
        }

        .modal__wrapper {
            display: flex;
        }
    }

    .similar__not-found {
        width : 100%;
    }
}

@media (max-width: 768px) {
    .product__container-info {
        .info__description-tablet {
            display: none;
        }
        .info__description {
            display: block;
        }
    }
}

@media (max-width: 606px) {
    .license_line {
        .modal__container {
            max-width: 337px;

            .modal__content {
                padding: 0 16px 24px;
            }
        }
    }

    .gallery__container-page {
        .gallery__list .product__item {
            min-height: auto;
        }
    }

    .product__container-info{
        .info__description {
            max-width: 100%;

            .cart__btn{
                width: 100%;
            }
        }
    }

    .similar__not-found {
        flex-wrap: wrap;
        .link {
            margin-left: 0;
            margin-top: 12px;
        }
    }
}
