@use "../../app/styles/variables" as v;

.info__filestype{
    flex-wrap  : wrap;
    line-height: 24px;
    
    .info__header {
        color      : v.$pc-gray-900;
        white-space: nowrap;
    }

    .info__content{
        color: v.$pc-gray-500;
    }
}