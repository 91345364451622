@use "../../../../app/styles/variables" as v;

.table-products__container {
    align-self      : center;
    width           : 100%;

    .admin__table-actions {
        margin-bottom  : 24px;
        justify-content: flex-end;

        .antd-dropdown__container .input-search {
            line-height: 22px;
        }
    }

    .table-products__header {
        width        : 100%;
        padding      : 0 !important;
        margin-bottom: 24px;
    }

    .admin__table {
        width: 100%;
        border-radius: 8px;
        border       : 1px solid v.$pc-gray-200;
        background   : v.$white;

        .admin__table-title {
            display: flex;
            justify-content: space-between;
            padding: 16px 24px;
            background-color: v.$pc-gray-25;
            border-radius: 12px 12px 0px 0px;
            border-bottom: 1px solid v.$pc-gray-200;

            .table-title__actions {
                gap: 24px;
            }

            .check__container {
                padding-left: 20px;
            }

            .button {
                padding: 0;
                line-height: 20px;
                .button__container-icon {
                    margin: 0;
                }
            }

            .total__wrapper {
                line-height: 20px;
                color: v.$pc-gray-400;
            }
        }
    }

    .admin__table-header, .admin__table-row {
        width: 100%;
    }

    .admin__table-header {
        display   : flex;
        height    : 44px;
        justify-content: space-between;
        background-color: v.$pc-gray-50;
        
        .button {
            .ml-15 {
                margin-left: 4px;
            }

            .button__img-right {
                height   : 16px;
                width    : 16px;
            }
        }

        .product-name, .product-views, 
        .product-date, .product-favorites,
        .product-status, .product-earned,
        .product-item__menu {
            line-height: 20px;
            color      : v.$pc-gray-500;
            font-family: NotoMedium;
            display    : flex;
            align-items: center;
            padding    : 12px 24px;
        }

        .product-name {
            min-width: 235px;
            padding-left: 56px;
        }
                
        .product-date {
            min-width: 154px;
        } 

        .product-views {
            min-width: 107px;
        }

        .product-favorites {
            min-width: 130px;
        }

        .product-status {
            min-width: 195px;
            max-width: 195px;

            h6 {
                line-height: 20px;
                color: v.$pc-gray-500;
                font-family: NotoMedium;
            }

            .button.link-gray {
                line-height: 20px;
                color: v.$pc-gray-500;
                font-family: NotoMedium;

                &:hover {
                    color: v.$pc-gray-500;
                }
            }
        }

        .product-earned {
            min-width: 116px;
        }

        .product-item__menu {
            min-width    : 52px;
            padding-right: 46px;
        }
    }

    .admin__table-body {
        position  : relative;
        height    : calc(100vh - 389px);
        overflow  : auto;
        overflow-y: scroll;

        .loading-admin__container {
            z-index: 0;
        }
    }

    .admin__table-row {
        justify-content: space-between;
        height: 68px;
        cursor: auto;

        &:hover {
            background-color: transparent !important;
        }
    }

    .product-name {
        min-width: 235px;
        width    : 235px;
        padding  : 6px 24px;

        .check__container {
            padding     : 10px;
            margin-right: 12px;
        }

        .modal-image {
            .modal__children {
                margin-right: 12px;
                min-width: 56px;
                max-width: 56px;
                height: 56px;
                border-radius: 4px;
                border: 1px solid v.$pc-gray-200;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 56px;
                    object-fit: cover;
                }
            }
        }

        .product-title {
            overflow: hidden;
            max-height: 50px;
        }

        h5 {
            line-height  : 24px;
            color        : v.$pc-gray-700;
            overflow     : hidden;
            text-overflow: ellipsis;
            text-wrap    : nowrap;
        }

        h6 {
            line-height: 20px;
            color      : v.$pc-gray-500;
        }
    }

    .product-date {
        width    : 154px;
        min-width: 154px;
        padding  : 12px 24px;

        h5 {
            font-family: NotoMedium;
            line-height: 24px;
            color      : v.$pc-gray-500;
        }

        h6 {
            line-height  : 20px;
            color        : v.$pc-gray-500;
        }
    }

    .product-views {
        padding   : 16px 24px;
        min-width : 107px;

        h6 {
            line-height  : 20px;
            color        : v.$pc-gray-500;
            font-family  : NotoBold;
        }
    }

    .product-favorites {
        min-width: 130px;
        padding  : 16px 24px;

        h6 {
            line-height  : 20px;
            color        : v.$pc-gray-500;
            font-family  : NotoBold;
        }
    }

    .product-status {
        min-width: 195px;
        max-width: 195px;
        padding: 16px 24px;
    }

    .product-earned {
        min-width: 116px;
        padding: 16px 24px;
    }

    .product-item__menu {
        min-width: 52px;
        padding: 16px;
    }

    .pagination__container {
        margin  : 0;
        padding : 16px 0;
        position: relative;

        .antd-dropdown__container {
            position: absolute;
            width: 83px;
            right: 22px;

            .input__wrapper {
                top: -175px;
            }
        }
    }
}

.product-title__pop {
    .ant-popover-inner {
        border-radius: 8px;
        border-top   : 1px solid v.$pc-gray-100;
        border-left  : 1px solid v.$pc-gray-100;
        border-right : 1px solid v.$pc-gray-100;
        box-shadow   : 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        padding      : 12px;
    }
}
