@use "../../../app/styles/variables" as v;

.tech-works__container {
    display   : flex;
    align-items: flex-start;
    border-radius: 8px;
    border    : 1px solid v.$pc-gray-300;
    background: v.$pc-gray-25;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    padding   : 12px 14px;
    width     : 100%;
    max-width : 600px;
    pointer-events: all;

    &.hidden {
        display: none;
    }

    .tech-works__title {
        line-height: 20px;
        font-size: 14px;
        color: v.$pc-gray-900;
        font-family: NotoSemiBold;
    }

    .tech-works_link {
        line-height: 20px;
        font-size: 14px;
        color: v.$pc-gray-500;
        font-family: NotoRegular;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-color: inherit;
    }

    .tech-works__actions {
        display: flex;
        align-items: center;

        .tech-works-close {
            cursor: pointer;
        }
    }
}
