@use "../../app/styles/variables" as v;

.hoverbackground__container {
  position: absolute;
  bottom  : 0;
  top     : 0;
  left    : 0;
  right   : 0;
  height  : 100%;
  padding : 16px;
  display : flex;
  color   : v.$pc-gray-100;

  &:hover {
    background: linear-gradient(180deg, rgba(57, 75, 126, 0.12) 0.06%, rgba(1, 13, 45, 0.55) 85.46%);
  }

  .product-title {
    color        : v.$white;
    overflow     : hidden;
    text-overflow: ellipsis;
    text-wrap    : nowrap;
  }

  .hoverbackground__info {
    .link-gray {
      color: v.$pc-gray-100;
      padding: 0;
      &:hover {
      text-decoration: underline;
      }
    }
  }
  &.resizer {
    .hoverbackground__info {
      .avatar-label__container {
        margin-bottom: 0;
        .avatar__wrapper {
          display: none;
        }
      }
    }
  }

  &.cut-text {
    .parent__hover {
      width: auto;
      justify-content: flex-end;
    }

    .hoverbackground__info {
      display: none;
    }
  }

  &.show {
    display: flex;
  }

  .parent__info {
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: none;
  }

  .download__ico-text {
    margin-left: 2px;
  }

  h6 {
    font-family: NotoSemiBold;
    line-height: 20px;
  }

  .avatar__wrapper {
    min-width   : 32px;
    margin-right: 10px;
  }

  .license {
    text-align : left;
    font-family: NotoMedium;
  }

  .download__button {
    .button__container-icon {
      margin-right: 0;
    }
    .download__ico-text {
      font-family: NotoSemiBold;
      font-size  : 16px;
      line-height: 24px;
      margin-left: 8px;
      display    : none;
    }

    &.download__icon {
      padding   : 12px 12px;
      background: v.$white;
      border    : 1px solid v.$pc-gray-300;
    }

    &.secondary {      
      .download__ico-text {
        display: block;
      }
    }
  }

  .hoverbackground__tooltip {
    color        : v.$pc-gray-700;
    margin-bottom: 4px;
    font-family  : NotoSemiBold;
  }
  
  .hoverbackground__learn-more{
    color      : v.$pc-gray-500;
    font-family: NotoRegular;
  }
}

.product-title_pop {
  .ant-popover-inner {
    border-radius: 8px;
    border-top   : 1px solid v.$pc-gray-100;
    border-left  : 1px solid v.$pc-gray-100;
    border-right : 1px solid v.$pc-gray-100;
    box-shadow   : 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    padding      : 12px;
  }
}
