@use "../../../app/styles/variables" as v;

.users__panel {
    padding-left: 32px;
    padding-top : 12px;
    flex-grow   : 1;
    overflow-x  : auto;
    overflow-y  : clip;

    .admin__table {
        width: 100%;
    }

    .admin__table-header {
        display: flex;
        height : 44px;
        width  : 100%;
        padding: 0 6px;
        
        .user-surname, .user-name, .user-create, .user-role, .user-email {
            line-height: 20px;
            color      : v.$pc-gray-500;
            padding    : 0 4px !important;
            font-family: NotoMedium;
            display    : flex;
            align-items: center;
            position   : sticky;
            left       : 0px;
            background : white;
            margin-left: 20px;
        }

        .user-name {
            padding-left: 18px;
            max-width   : 243px;
            min-width   : 243px;
        }

        .user-surname {
            max-width: 195px;
            min-width: 195px;
        }
        
        .user-role {
            min-width: 110px;
            max-width: 110px;
        } 

        .user-email {
            min-width: 241px;
            max-width: 241px;
        }

        .user-create {
            min-width: 116px;
            max-width: 116px;
        }
    }

    .admin__table-body {
        height: calc(100vh - 276px);
    }

    .admin__table-row {
        height : 68px;
        padding: 6px;
        width  : 100%;
        cursor : pointer;

        &.current {
            background: v.$pc-accent-100;
        }
    }

    .pagination__container {
        margin    : 0;
        padding   : 12px 0;
        border-top: 1px solid v.$pc-gray-200;
        position  : relative;
        justify-content: space-between;

        .antd-dropdown__container {
            width       : 82px;
            position    : absolute;
            right       : 0;
            margin-right: 32px;
        }
    }

    .user-name{
        min-width    : 259px;
        max-width    : 259px;
        padding-right: 24px;

        .user-avatar__wrapper {
            margin-right : 12px;
        }

        .user-firstname {
            overflow: hidden;
        }

        h5 {
            line-height  : 24px;
            color        : v.$pc-gray-900;
            font-family  : NotoMedium;
            text-overflow: ellipsis;
            overflow     : hidden; 
            white-space  : nowrap;
            padding-right: 10px;
        }

        h6 {
            line-height: 20px;
            color      : v.$pc-gray-500;
        }
    }

    .user-surname {
        min-width: 211px;
        max-width: 211px;
        padding  : 16px 24px!important;

        h5 {
            line-height  : 24px;
            color        : v.$pc-gray-900;
            font-family  : NotoMedium;
            text-overflow: ellipsis;
            overflow     : hidden; 
            white-space  : nowrap;
            padding-right: 10px;
        }
    }

    .user-role {
        min-width: 126px;
        max-width: 126px;
        padding  : 16px 24px!important;

        h5 {
            line-height  : 24px;
            color        : v.$pc-gray-900;
            font-family  : NotoMedium;
            text-overflow: ellipsis;
            overflow     : hidden; 
            white-space  : nowrap;
            padding-right: 10px;
        }

        h6 {
            line-height: 20px;
            color      : v.$pc-gray-500;
        }
    }

    .user-email {
        min-width: 257px;
        max-width: 257px;
        padding  : 16px 24px!important;

        h6 {
            color        : v.$pc-gray-500;
            font-family  : NotoMedium;
            line-height  : 20px;
            text-overflow: ellipsis;
            overflow     : hidden; 
            white-space  : nowrap;
        }

        .noactivated {
            height: 24px;
            padding      : 2px 8px 2px 6px;
            align-items  : center;
            border-radius: 16px;
            background   : v.$pc-warning-50;
            width        : fit-content;
            max-width    : 100%;

            h6 {
                margin-left: 4px;
                color      : v.$pc-warning-700;
            }
        }
    }

    .user-create {
        min-width: 132px;
        max-width: 132px;
        padding  : 16px 24px!important;

        h5 {
            line-height  : 24px;
            color        : v.$pc-gray-500;
            font-family  : NotoMedium;
        }

        h6 {
            color      : v.$pc-gray-500;
        }
    }
}

@media (max-width: 1420px) {
    .users__panel {
        .admin__table-body {
            height: calc(100vh - 294px);
        }
        .admin__table, .admin__table-header, .admin__table-row {
            width  : 1017px;
        }

        .pagination__container {
            padding-bottom: 10px;
        }
    }
}

@media (max-width: 768px) {
    .users__panel {
        .pagination__container {
            padding-bottom: 10px;

            .antd-dropdown__container {
                display: none;
            }
        }
    }
}
