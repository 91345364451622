@use "../../../app/styles/variables" as v;

.menu-default {
    border-radius: 8px;
    border: 1px solid v.$pc-gray-100;
    overflow: hidden;
    width: 240px;
    box-shadow:
        0px 4px 6px -2px rgba(16, 24, 40, 0.03),
        0px 12px 16px -4px rgba(16, 24, 40, 0.08);

    .menu__item {
        &.item__wrapper-component {
            padding: 0;
        }

        .menu__item-icon {
            padding: 10px 12px 10px 16px;
        }

        .menu__item-component {
            .author__wrapper {
                width: 100%;
            }

            .avatar__wrapper {
                margin-right: 12px;
                &.sm {
                    width: 40px;
                    height: 40px;
                }
            }

            h6 {
                width: 100%;
                padding-right: 16px;
            }

            .link,
            .menu_logout {
                padding: 10px 16px 10px 0px;
                display: block;
                text-align: left;
                color: #344054;
                font-family: NotoMedium;
                line-height: 20px;
                font-size: 14px;
                text-decoration: none;
                cursor: pointer;
            }

            .avatar-label__container {
                padding: 12px 16px;
            }
        }

        .author__name,
        .author__email {
            word-break: break-all;
            color: v.$pc-gray-500;
        }

        .author__name {
            color: v.$pc-gray-700;
            font-family: NotoSemiBold;
            line-height: 20px;
            width: 100%;
        }

        &.divider {
            border-bottom: 1px solid v.$pc-gray-100;
        }

        &:hover {
            cursor: pointer;
            background: v.$pc-gray-50;
        }

        &:first-child {
            background: transparent;
            cursor: default;
            pointer-events: none;
        }

        &.mobile {
            display: none;
        }
    }
}

@media (max-width: 606px) {
    .menu-dropdown {
        li.menu__item {
            &.mobile {
                display: block;
            }
        }
    }
}
