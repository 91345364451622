@use "../../app/styles/variables" as v;

.cart__scroll-resume {
    width: 100%;
    padding: 16px 48px;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    background: v.$white;
    z-index: 98;
    border-top: 1px solid v.$pc-gray-200;
    display: none;

    .cart-resume__item-total {
        display: flex;
        justify-content: space-between;
    }

    .cart__scroll-resume__total {
        display: flex;
        align-items: center;
    }

    .cart__scroll-resume__total,
    .cart__scroll-resume__total-desc {
        color: v.$pc-gray-900;
        font-family: NotoSemiBold;
        line-height: 32px;
        font-size: 24px;
    }

    .cart__scroll-resume_vat {
        color: v.$pc-gray-500;
        margin-left: 8px;
    }

    .pay__link {
        width: 100%;
        justify-content: center;
        height: 44px;
        margin: 12px 0 0 0;
        line-height: 20px !important;
    }
}

@media (max-width: 768px) {
    .cart__scroll-resume {
        display: block;
    }
}
