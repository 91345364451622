@use "../../../../app/styles/variables" as v;

.chat-typing__container {
    display        : flex;
    align-items    : flex-end;
    justify-content: space-between;
    width          : 100%;
    padding        : 19px 16px 20px 24px;

    &.disabled {
        background-color: #fff;
        opacity         : 0.7;
        pointer-events  : none;
    }
}

.chat__typing-form {
    flex      : 1;
    margin    : 0 16px;
    min-height: 40px;
    position  : relative;
    width     : 74%;
   
    .input__container {
        border    : none !important;
        box-shadow: none !important;
    }

    textarea {
        outline      : none!important;
        border-radius: 0;
        padding      : 7px 0 0 0;
        height       : 40px;
        border       : none!important;
        resize       : none;
        font-size    : 16px;
        line-height  : 24px;
        color        : v.$pc-gray-500;
        width        : 100%;
    }
}

.chat__typing-smile {
    margin-right: 20px;
}

.button {
    &.chat__typing-send-btn {
        width          : 40px;
        height         : 40px;
        padding        : 0;
        display        : flex;
        align-items    : center;
        justify-content: center;
    }
}

.chat__counter-container {
  position: relative;
  color: #D0D5DD;
}

.chat__counter {
  position   : absolute;
  right      : 0;
  bottom     : -12px;
  line-height: 0;
  font-size  : 12px;
  text-align : right;
}

.chat__typing-attach {
    cursor: pointer;
    width : 100%;
    height: 100%;
}

.chat__typing {
    background: v.$white;
    width     : 100%;
    position    : sticky;
    bottom      : 0;
    left        : 0;
    right       : 0;

    .user-project__img-wrapper {
        background: transparent;
        width     : 40px;
        min-width : 40px;
        height    : 40px;
        border    : none;

        &.disabled {
            opacity: .4;
            pointer-events: none;
        }

        .button__image-add {
            border    : none;
            background: transparent;
            width     : 32px;
            height    : 32px;
            position  : relative;
            left      : 0;
            top       : 0;
        }
    }

}
